using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    /// <summary>
    /// gui for selecting the time range
    /// </summary>
    public class TrendDataSelectTime : ITrendPane
    {
        private class TrendTimeDataRow : MovableTable.MovableTableRow
        {

            public TrendWindow.TrendPlotDataPoint DataPoint { private set; get; }


            public TrendTimeDataRow(TrendWindow.TrendPlotDataPoint dataPoint)
            {
                this.DataPoint = dataPoint;
                //this.ForeColor = dataPoint.Color;

                HTMLInputElement colorInputElement = null;
                try
                {
                    colorInputElement = new HTMLInputElement()
                    {
                        Type = InputType.Color,
                        Value = dataPoint.Color
                    };
                }
                catch
                {
                    colorInputElement = new HTMLInputElement()
                    {
                        Type = InputType.Text,
                        Value = dataPoint.Color,
                        Style = { Color = dataPoint.Color }
                    };
                }


                colorInputElement.OnChange += @event =>
                {
                    DataPoint.Color = colorInputElement.Value;
                };

                HTMLInputElement increaseElement = new HTMLInputElement()
                {
                    Type = InputType.Number,
                    Value = dataPoint.Increase.ToString(),
                    Style = { Width = "50px"}
                };
                increaseElement.OnChange += Event =>
                {
                    int tmp = 0;
                    int.TryParse(increaseElement.Value, out tmp);
                    dataPoint.Increase = tmp;
                };
                
                
                this.Cells.Add(new MovableTable.MovableTableCell(dataPoint.IndexItem.StationDescription));
                this.Cells.Add(new MovableTable.MovableTableCell(dataPoint.IndexItem.DataDescription));
                this.Cells.Add(new MovableTable.MovableTableCell(dataPoint.IndexItem.Unit));
                this.Cells.Add(new MovableTable.MovableTableCell(Functions.DatetimeToLocalDateTimeString(dataPoint.IndexItem.ReadFirst).ToString()));
                this.Cells.Add(new MovableTable.MovableTableCell(Functions.DatetimeToLocalDateTimeString(dataPoint.IndexItem.ReadLast).ToString()));
                this.Cells.Add(new MovableTable.MovableTableCell(colorInputElement));
                this.Cells.Add(new MovableTable.MovableTableCell(increaseElement));
            }
            

        }
        
        public DateTime SelectRangeStart
        {
            get
            {
                if (_selectedFixedRange)
                {
                    var val = (_fixedRangesSelect.ChildNodes[_fixedRangesSelect.SelectedIndex] as HTMLOptionElement).Value;
                    return DateTime.Now - TimeSpan.FromHours(int.Parse(val));
                }
                else
                {
                    DateTime jsStart = ((DateTime)_freeRangeStartDateElement.ValueAsDate);
                    if (!string.IsNullOrWhiteSpace(_freeRangeStartTimeElement.Value))
                    {
                        jsStart = jsStart.ToUniversalTime().AddMilliseconds((int)_freeRangeStartTimeElement.ValueAsNumber);
                    }
                    return jsStart;
                    
                }
            }
        }

        public DateTime SelectRangeEnd
        {
            get
            {
                if (_selectedFixedRange)
                {
                    return DateTime.Now;
                }
                else
                {
                    DateTime jsEnd = ((DateTime)_freeRangeEndDateElement.ValueAsDate);
                    if (!string.IsNullOrWhiteSpace(_freeRangeEndTimeElement.Value))
                    {
                        jsEnd = jsEnd.ToUniversalTime().AddMilliseconds((int)_freeRangeEndTimeElement.ValueAsNumber);
                    }
                    return jsEnd;
                }
            }
        }

        public HTMLDivElement ContendPane { private set; get; }

        public Action GoNext { set; get; }
        public Action GoBack { set; get; }
        public Action Export { set; get; }

        private class TimeRangeOptionElement
        {
            public HTMLOptionElement OptionElement { get; private set; }
            public string Text { private set; get; }
            public TimeSpan Span { private set; get; }

            public TimeRangeOptionElement(string text, TimeSpan span)
            {
                this.OptionElement = new HTMLOptionElement()
                {
                    TextContent = text,
                    Value = span.TotalHours.ToString()
                };
                Text = text;
                Span = span;
            }
        }

        private HTMLFieldSetElement _fixedRangeFieldset;
        private HTMLFieldSetElement _freeRangeFieldset;
        private HTMLSelectElement _fixedRangesSelect;

        private HTMLInputElement _freeRangeStartDateElement;
        private HTMLInputElement _freeRangeStartTimeElement;
        private HTMLInputElement _freeRangeEndDateElement;
        private HTMLInputElement _freeRangeEndTimeElement;

        private HTMLButtonElement _exportButton;

        private MovableTable _selectedDataTable;
        public MainWindow MainWindow{ set; get; }
        public IServerConnection ServerConnection { set; get; }

        private TrendWindow.TrendPlotDataPoint[] _selectedData;
        private bool _selectedFixedRange;

        private string _database;
        
        public void ToggleExportButton(bool active)
        {
            _exportButton.Disabled = !active;

        }

        public TrendDataSelectTime(DateTime possibleStart, DateTime possibleEnd, TrendWindow.TrendPlotDataPoint[] selecteDataPoints, string database)
        {
            _database = database;
            _selectedData = selecteDataPoints;
            ContendPane = new HTMLDivElement()
            {
                Id = "trend_select_time_pane"
            };

            HTMLFieldSetElement selectedDataFieldset = new HTMLFieldSetElement()
            {
                Id = "trend_select_time_range_data"
            };
            selectedDataFieldset.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Ausgewählte Daten"
            });

            HTMLDivElement scrollDiv = new HTMLDivElement()
            {
                ClassName = "fieldset_overflow_div"
            };

            
            _selectedDataTable = new MovableTable("trend_select_time_datatbl", "mvtbl_table");
            _selectedDataTable.AllowResizeColumns();
            _selectedDataTable.AllowSortColumns();

            _selectedDataTable.BeginUpdate();
            _selectedDataTable.AddColumn("Station", 180);
            _selectedDataTable.AddColumn("Datenpunkt", 220);
            _selectedDataTable.AddColumn("Einheit", 60);
            _selectedDataTable.AddColumn("Erster Wert", 160);
            _selectedDataTable.AddColumn("Letzter Wert", 160);
            _selectedDataTable.AddColumn("Farbe", 60);
            _selectedDataTable.AddColumn("Anhebung", 85);

            foreach (var trendPlotDataPoint in selecteDataPoints)
            {
                _selectedDataTable.AddRow(new TrendTimeDataRow(trendPlotDataPoint));
            }
            _selectedDataTable.EndUpdate();

            scrollDiv.AppendChild(_selectedDataTable.HTMLElement);
            selectedDataFieldset.AppendChild(scrollDiv);

            HTMLFieldSetElement rangeFieldset = new HTMLFieldSetElement()
            {
                Id = "trend_select_time_range_fieldset"
            };
            rangeFieldset.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Zeitraum"
            });

            HTMLParagraphElement rangeInfoElement = new HTMLParagraphElement()
            {
                Id = "trend_select_time_range_p_range"
            };
            rangeInfoElement.AppendChild(new Text("möglicher Bereich von"));
            rangeInfoElement.AppendChild(new HTMLSpanElement()
            {
                TextContent = Functions.DatetimeToLocalDateTimeString(possibleStart)       
            });
            rangeInfoElement.AppendChild(new Text("bis"));
            rangeInfoElement.AppendChild(new HTMLSpanElement()
            {
                TextContent = Functions.DatetimeToLocalDateTimeString(possibleEnd)
            });
            rangeFieldset.AppendChild(rangeInfoElement);


            #region fixed range fieldset
            //fieldset with head
            _fixedRangeFieldset = new HTMLFieldSetElement()
            {
                Id = "trend_select_time_fixed_fieldset"
            };
            HTMLLegendElement fixedRangeFieldsetLegend = new HTMLLegendElement();
            
            HTMLInputElement fixedRangeFieldsetLegendBox = new HTMLInputElement()
            {
                Type = InputType.Radio,
                Name = "trend_select_time_range_type_select",
                Checked = true
            };
            fixedRangeFieldsetLegendBox.OnChange = @event =>
            {
                RangeTypeSelectionChanged(fixedRangeFieldsetLegendBox.Checked);
            };

            fixedRangeFieldsetLegend.AppendChild(fixedRangeFieldsetLegendBox);
            fixedRangeFieldsetLegend.AppendChild(new Text("Vorgegeben"));
            _fixedRangeFieldset.AppendChild(fixedRangeFieldsetLegend);

            HTMLParagraphElement fixedRangeSelectPane = new HTMLParagraphElement();
            fixedRangeSelectPane.AppendChild(new Text("Zeitraum:"));
            _fixedRangesSelect = new HTMLSelectElement();

            //fill select
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte Stunde", TimeSpan.FromHours(1)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 2 Stunden", TimeSpan.FromHours(2)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 4 Stunden", TimeSpan.FromHours(4)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 8 Stunden", TimeSpan.FromHours(8)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 12 Stunden", TimeSpan.FromHours(12)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzer Tag", TimeSpan.FromDays(1)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 2 Tage", TimeSpan.FromDays(2)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 5 Tage", TimeSpan.FromDays(5)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte Woche", TimeSpan.FromDays(7)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 2 Wochen", TimeSpan.FromDays(14)).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzter Monat", (DateTime.Now - DateTime.Now.AddMonths(-1))).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 2 Monate", (DateTime.Now - DateTime.Now.AddMonths(-2))).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letzte 6 Monate", (DateTime.Now - DateTime.Now.AddMonths(-6))).OptionElement);
            _fixedRangesSelect.AppendChild(new TimeRangeOptionElement("letztes Jahr", (DateTime.Now - DateTime.Now.AddYears(-1).AddDays(-1))).OptionElement);

            fixedRangeSelectPane.AppendChild(_fixedRangesSelect);
            _fixedRangeFieldset.AppendChild(fixedRangeSelectPane);
            #endregion


            #region non fixed range

            //create fieldset
            _freeRangeFieldset = new HTMLFieldSetElement()
            {
                Id = "trend_select_time_free_fieldset"
            };
            HTMLLegendElement freeRangeFieldsetLegend = new HTMLLegendElement();
            
            HTMLInputElement freeRangeFieldsetLegendBox = new HTMLInputElement()
            {
                Type = InputType.Radio,
                Name = "trend_select_time_range_type_select"
            };
            freeRangeFieldsetLegendBox.OnChange = @event =>
            {
                RangeTypeSelectionChanged(!freeRangeFieldsetLegendBox.Checked);
            };

            freeRangeFieldsetLegend.AppendChild(freeRangeFieldsetLegendBox);
            freeRangeFieldsetLegend.AppendChild(new Text("Vorgegeben"));
            _freeRangeFieldset.AppendChild(freeRangeFieldsetLegend);

            //do inner stuff
            HTMLParagraphElement freeRangeSelectStartPane = new HTMLParagraphElement();
            freeRangeSelectStartPane.AppendChild(new Text("Von:"));

            _freeRangeStartDateElement = new HTMLInputElement()
            {
                ClassName = "trend_freerange_start_date",
                Type = InputType.Date,
                ValueAsDate =  (Date)possibleStart
            };
            _freeRangeStartTimeElement = new HTMLInputElement()
            {
                ClassName = "trend_freerange_start_time",
                Type = InputType.Time,
            };
            _freeRangeStartTimeElement.ValueAsNumber = ((int)possibleStart.TimeOfDay.TotalMinutes) * 60 * 1000;

            freeRangeSelectStartPane.AppendChild(_freeRangeStartDateElement);
            freeRangeSelectStartPane.AppendChild(_freeRangeStartTimeElement);

            HTMLParagraphElement freeRangeSelectEndPane = new HTMLParagraphElement();
            freeRangeSelectEndPane.AppendChild(new Text("Bis:"));

            _freeRangeEndDateElement = new HTMLInputElement()
            {
                ClassName = "trend_freerange_end_date",
                Type = InputType.Date,
                ValueAsDate = (Date)possibleEnd
            };
            _freeRangeEndTimeElement = new HTMLInputElement()
            {
                ClassName = "trend_freerange_end_time",
                Type = InputType.Time,
            };
            _freeRangeEndTimeElement.ValueAsNumber = ((int)possibleEnd.TimeOfDay.TotalMinutes) * 60 * 1000;

            freeRangeSelectEndPane.AppendChild(_freeRangeEndDateElement);
            freeRangeSelectEndPane.AppendChild(_freeRangeEndTimeElement);

            _freeRangeFieldset.AppendChild(freeRangeSelectStartPane);
            _freeRangeFieldset.AppendChild(freeRangeSelectEndPane);

            #endregion


            HTMLFormElement wrapperForm = new HTMLFormElement();

            wrapperForm.AppendChild(_fixedRangeFieldset);
            wrapperForm.AppendChild(_freeRangeFieldset);

            rangeFieldset.AppendChild(wrapperForm);




            HTMLDivElement buttonDiv = new HTMLDivElement()
            {
                Id = "trend_select_date_button_pane"
            };
            HTMLButtonElement saveButton = new HTMLButtonElement()
            {
                TextContent = "Speichern",
                Id = "trend_select_date_button_save",
                OnClick = @ecent =>
                {
                    Save();
                }
            };
            _exportButton = new HTMLButtonElement()
            {
                TextContent = "Export",
                Id = "trend_select_date_button_export",
                OnClick = @event =>
                {
                    Export();
                }
            };

            HTMLButtonElement backButton = new HTMLButtonElement()
            {
                TextContent = "Zurück",
                OnClick = @event =>
                {
                    GoBack();
                }
            };
            HTMLButtonElement nextButton = new HTMLButtonElement()
            {
                TextContent = "Weiter",
                OnClick = @event =>
                {
                    if((this.SelectRangeEnd - this.SelectRangeStart) > new TimeSpan(365,0,0,0))
                    {
                        MessageBox.Show("Der Zeitraum kann nicht größer als ein Jahr sein", MessageBox.MessageBoxTypes.Info, 3000, this.ContendPane);
                        return;
                    }
                    GoNext();
                }
            };


            buttonDiv.AppendChild(saveButton);
            buttonDiv.AppendChild(nextButton);
            buttonDiv.AppendChild(backButton);
            buttonDiv.AppendChild(_exportButton);


            ContendPane.AppendChild(selectedDataFieldset);
            ContendPane.AppendChild(rangeFieldset);
            ContendPane.AppendChild(buttonDiv);

            

            RangeTypeSelectionChanged(true);
        }
        

        private void RangeTypeSelectionChanged(bool isFixedSelect)
        {
            if (isFixedSelect)
            {
                _selectedFixedRange = true;
                _fixedRangeFieldset.ClassList.Remove("trend_select_fieldset_disabled");
                _fixedRangesSelect.Disabled = false;

                _freeRangeFieldset.ClassList.Add("trend_select_fieldset_disabled");
                _freeRangeStartDateElement.Disabled = true;
                _freeRangeStartTimeElement.Disabled = true;
                _freeRangeEndDateElement.Disabled = true;
                _freeRangeEndTimeElement.Disabled = true;
            }
            else
            {
                _selectedFixedRange = false;
                _fixedRangeFieldset.ClassList.Add("trend_select_fieldset_disabled");
                _fixedRangesSelect.Disabled = true;

                _freeRangeFieldset.ClassList.Remove("trend_select_fieldset_disabled");
                _freeRangeStartDateElement.Disabled = false;
                _freeRangeStartTimeElement.Disabled = false;
                _freeRangeEndDateElement.Disabled = false;
                _freeRangeEndTimeElement.Disabled = false;
            }
        }

        public void Close()
        {
            this._selectedDataTable.Dispose();
            
        }
        
        private void Save()
        {

            var val = (_fixedRangesSelect.ChildNodes[_fixedRangesSelect.SelectedIndex] as HTMLOptionElement).Value;

            TrendSaveOverlay overlay = new TrendSaveOverlay(this.MainWindow, this.ServerConnection, !_selectedFixedRange, SelectRangeStart, SelectRangeEnd, TimeSpan.FromHours(int.Parse(val)), _database, this._selectedData);
 

        }
    }
}
