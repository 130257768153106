using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;
using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class TrendShowPlot : ITrendPane
    {

        private class TrendPlotData
        {

            public object[] Labels { set; get; }
            public object[][] Data { set; get; } //3d array fuck yeaahhh javascript!!

            public TrendPlotData(IEnumerable<TrendDataSeries> values, TrendWindow.TrendPlotDataPoint[] selectedItems)
            {
                var arrValues = System.Linq.Enumerable.ToArray<TrendDataSeries>(values);
                Labels = new object[arrValues.Length];
                Data = new object[arrValues.Length][];

                for (int i = 0; i < arrValues.Length; i++)
                {
                    var pointData = arrValues[i];

                    string color = "";
                    double increase = 0;
                    foreach (var trendPlotDataPoint in selectedItems)
                    {
                        if (trendPlotDataPoint.IndexItem.Id == pointData.IndexItem.Id)
                        {
                            color = trendPlotDataPoint.Color;
                            increase = trendPlotDataPoint.Increase;
                        }
                    }
                    
                    if (string.IsNullOrEmpty(color))
                    {
                        color = Functions.HslToRgb(((new Random(i)).Next(23) * 15), 1, 1);
                    }

                    
                    Data[i] = new object[System.Linq.Enumerable.Count<TrendDataSeries.Point>(pointData.Values)];
                    Labels[i] = new
                    {
                        label = pointData.IndexItem.StationDescription + " - " +  pointData.IndexItem.DataDescription + ((increase == 0) ? "" : " (Anhebung: " + ((increase > 0) ? "+": "") + increase + ")"),
                        color = color
                    };

                    DateTime now = DateTime.Now;
                    //Console.WriteLine(Functions.DatetimeToLocalDateString(now));
                    //Console.WriteLine(Functions.DatetimeToLocalDateString(Functions.UnixTimeToDatetime(Functions.DatetimeToUnixTime(now))));
                    
                    DateTime startTime = pointData.StartTime;
                    
                    //create empty
                    if (Data[i].Length == 0)
                    {
                        Data[i] = new[]
                        {
                            new object[] {0, 0}
                        };
                    }


                    //Console.WriteLine("starting with " +Functions.DatetimeToLocalDateString(startTime));

                    var pointValues = System.Linq.Enumerable.ToArray<TrendDataSeries.Point>(pointData.Values);
                    for (int j = 0; j < pointValues.Length; j++)
                    {
                        //Console.WriteLine("timepoint at " + Functions.DatetimeToLocalDateString(dCalc) +  "- " + Functions.UnixTimeToLocalDateString(calcTime));

                        long pointTime = pointValues[j].T;
                        var time = startTime.AddSeconds(pointTime);
                        var val = (pointValues[j].V + (double)increase);
                        
                        Data[i][j] = new object[] 
                        {
                            new Date(time.Year, time.Month -1, time.Day, time.Hour, time.Minute, time.Second), val
                            
                        };
                        //Data[i][j] = new KeyValuePair<long, double>((pointData.starttime + pointData.values[j].t), pointData.values[j].v);    
                    }
                }
                //Console.WriteLine(Data);
            }
            
        }


        public void ToggleExportButton(bool active)
        {

        }


        public HTMLDivElement ContendPane { private set; get; }

        public Action GoBack { set; get; }

        public MainWindow MainWindow { set; get; }
        public IServerConnection ServerConnection { set; get; }

        private HTMLDivElement _plotPane;

        private object _trendPlot;

        private TrendWindow.TrendPlotDataPoint[] _selectedItems;

        public TrendShowPlot(TrendWindow.TrendPlotDataPoint[] selectedItems)
        {
            _selectedItems = selectedItems;
            ContendPane = new HTMLDivElement();
            _plotPane = new HTMLDivElement()
            {
                Id = "trend_plot_plot"
            };



            HTMLDivElement buttonDiv = new HTMLDivElement()
            {
                Id = "trend_plot_button_pane"
            };


            HTMLButtonElement resetButton = new HTMLButtonElement()
            {
                TextContent = "Reset Zoom",
                Id = "trend_plot_reset_zoom",
                OnClick = @event =>
                {
                    ResetZoom();
                }
            };

            HTMLButtonElement backButton = new HTMLButtonElement()
            {
                TextContent = "Zurück",
                OnClick = @event =>
                {
                    GoBack();
                }
            };

            



            buttonDiv.AppendChild(backButton);
            buttonDiv.AppendChild(resetButton);

            ContendPane.AppendChild(_plotPane);
            ContendPane.AppendChild(buttonDiv);
        }


        public void ShowData(IEnumerable<TrendDataSeries> trendValue)
        {
            //todo parsing
            var plotData = new TrendPlotData(trendValue, _selectedItems);
            //var jsonData = JSON.Stringify(plotData);


            _trendPlot = Script.Call<object>("ShowPlot","trend_plot_plot", plotData, "VisuPlus TrendPlot");
        }
        


        private void ResetZoom()
        {
            if (_trendPlot != null)
            {
                Script.Call("PlotResetZoom", _trendPlot);
            }
        }

        public void Resize()
        {
            if (_trendPlot != null)
            {
                Script.Call("PlotResize", _trendPlot);
            }
        }


        public void Close()
        {
            if (_trendPlot != null)
            {
                Script.Call("DestroyPlot", _trendPlot);
            }
        }
    }
}
