using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    class TrendLoadOverlay
    {
        private class LoadSeriesTableRow: MovableTable.MovableTableRow
        {
            public TrendUserSelection Selection { set; get; }

            public LoadSeriesTableRow(TrendUserSelection selection)
            {
                Selection = selection;
                this.AddCell(selection.Name);
                this.AddCell(selection.Description);
                this.AddCell(selection.Username);
                this.AddCell(selection.Database);                
                this.AddCell(selection.IsFreeRange ? "frei" : "fix");
            }
        }


        public Action<bool> DialogFinished;
        public int SelectedId { set; get; }

        private ToolWindow _toolWindow;
        private IServerConnection _serverConnection;
        
        private MovableTable _seriesTable; 

        private HTMLButtonElement _loadButton;
        private HTMLButtonElement _deleteButton;
        
        MainWindow _mainWindow;

        public TrendLoadOverlay(MainWindow mainWindow, IServerConnection serverConnection)
        {
            this._mainWindow = mainWindow;
            this._serverConnection = serverConnection;
            
            Overlay.Show();

            _toolWindow = new ToolWindow(mainWindow, "Trendauswahl laden", "trend_saved_series_box_load", 1024, 768);
            _toolWindow.MakeCloseable();
            _toolWindow.Closed += WindowClosed;
            Window.OnKeyDown += OnKeyDown;
            mainWindow.BringWindowToFront(_toolWindow);
            
            var tableFieldset = new HTMLFieldSetElement() { ClassName = "trend_saved_series_table_box" };
            tableFieldset.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Gepeicherte Datensätze"
            });

            _seriesTable = new MovableTable("", "mvtbl_table");
            _seriesTable.AllowSelectColumns();
            _seriesTable.AllowSortColumns();

            _seriesTable.AddColumn("Name");
            _seriesTable.AddColumn("Beschreibung");
            _seriesTable.AddColumn("Benutzer");
            _seriesTable.AddColumn("Datenbank");
            _seriesTable.AddColumn("Intervall");

            var overflowDiv = new HTMLDivElement()
            {
                ClassName = "fieldset_overflow_div"
            };
            overflowDiv.AppendChild(_seriesTable.HTMLElement);
            tableFieldset.AppendChild(overflowDiv);
            
           
            var buttonFieldset = new HTMLFieldSetElement() { ClassName = "trend_saved_series_button_box" };

            _deleteButton = new HTMLButtonElement()
            {
                TextContent = "Löschen",
                OnClick = (ev) => { Delete(); }
            };
            _loadButton = new HTMLButtonElement()
            {
                ClassName = "trend_load_saved_series_button",
                TextContent = "Laden",
                OnClick = (ev) => { Load(); }
            };



            buttonFieldset.AppendChild(_deleteButton);
            buttonFieldset.AppendChild(_loadButton);

            _toolWindow.GetContendArea().AppendChild(tableFieldset);
            _toolWindow.GetContendArea().AppendChild(buttonFieldset);
        }


        private void Load()
        {
            var selectedRow = System.Linq.Enumerable.First<MovableTable.MovableTableRow>(_seriesTable.SelectedRows);
            if(selectedRow != null)
            {
                this.SelectedId = selectedRow.As<LoadSeriesTableRow>().Selection.Id;
            }
            DialogFinished(true);
            CloseWindow();
        }

        private void Delete()
        {
            var selectedRow = System.Linq.Enumerable.First<MovableTable.MovableTableRow>(_seriesTable.SelectedRows);
            if (selectedRow != null)
            {
                if (Window.Confirm("Soll der Eintrag wirklick gelöscht werden?"))
                {
                    _serverConnection.TrendUserSelectionDelete(selectedRow.As<LoadSeriesTableRow>().Selection.Id,
(Action<bool>)(                        success => {
                            MessageBox.Show("Gelöscht", MessageBox.MessageBoxTypes.Info, 2000, this._mainWindow.ContendArea);
                            FillTable();
                        }),
(Action<string>)(                        error => {
                            MessageBox.Show("Konnte nicht gelöscht werden", MessageBox.MessageBoxTypes.Error, 2000, this._mainWindow.ContendArea);
                        }
)                        );
                }
            }

        }


        public void FillTable()
        {
            _serverConnection.TrendUserSelectionll(
(System.Action<RGAVerl.VisuPlus2000net.Models.TrendUserSelection[]>)(                finished =>
                {
                    _seriesTable.BeginUpdate();
                    _seriesTable.ClearRows();
                    _seriesTable.AddRowsRange(System.Linq.Enumerable.Select<TrendUserSelection,LoadSeriesTableRow>(finished,(Func<TrendUserSelection,LoadSeriesTableRow>)(row => new LoadSeriesTableRow(row))));
                    _seriesTable.EndUpdate();
                }),
(Action<string>)(                error => 
                {
                    MessageBox.Show("Fehler beim laden", MessageBox.MessageBoxTypes.Error, 2000, this._mainWindow.ContendArea);
                }
)           );
        }

        private void OnKeyDown(KeyboardEvent keyboardEvent)
        {
            if (keyboardEvent.KeyCode == 27)
            {
                CloseWindow();
            }
        }
        

        private void WindowClosed()
        {
            Window.OnKeyDown -= OnKeyDown;
            Overlay.Hide();
            DialogFinished(false);
        }


        private void CloseWindow()
        {
            _toolWindow.CloseWindow();
        }
    }
}
