using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    /// <summary>
    /// gui for selecting the data 
    /// </summary>
    public class TrendDataSelectPoints : ITrendPane
    {
        private class TrendDataRow : MovableTable.MovableTableRow
        {
            

            public TrendWindow.TrendPlotDataPoint DataPoint { private set; get; }
            private HTMLInputElement _selectedInput;

            public TrendDataRow(TrendWindow.TrendPlotDataPoint dataPoint)
            {
                this.DataPoint = dataPoint;
                //this.ForeColor = dataPoint.Color

                HTMLInputElement colorInputElement = null;

                try
                {
                    colorInputElement = new HTMLInputElement()
                    {
                        Type = InputType.Color,
                        Value = dataPoint.Color
                    };
                }
                catch
                {
                    colorInputElement = new HTMLInputElement()
                    {
                        Type = InputType.Text,
                        Value = dataPoint.Color,
                        Style = { Color =  dataPoint.Color}
                    };
                }


                
                colorInputElement.OnChange += @event =>
                {
                    DataPoint.Color = colorInputElement.Value;
                    //Update();
                };

                _selectedInput = new HTMLInputElement()
                {
                    Type = InputType.Checkbox,
                    Checked = dataPoint.Selected,
                    
                };
                _selectedInput.OnChange += @event =>
                {
                    dataPoint.Selected = _selectedInput.Checked;
                    
                };


                this.DoubleClicked += () =>
                {

                    ChangeSelect(!this.DataPoint.Selected);
                    //Update();
                };
                
                this.Cells.Add(new MovableTable.MovableTableCell(_selectedInput));
                this.Cells.Add(new MovableTable.MovableTableCell(dataPoint.IndexItem.StationDescription));
                this.Cells.Add(new MovableTable.MovableTableCell(dataPoint.IndexItem.DataDescription));
                this.Cells.Add(new MovableTable.MovableTableCell(dataPoint.IndexItem.Unit));
                this.Cells.Add(new MovableTable.MovableTableCell(Functions.DatetimeToLocalDateTimeString(dataPoint.IndexItem.ReadFirst).ToString()));
                this.Cells.Add(new MovableTable.MovableTableCell(Functions.DatetimeToLocalDateTimeString(dataPoint.IndexItem.ReadLast).ToString()));
                this.Cells.Add(new MovableTable.MovableTableCell(colorInputElement));
            }

            public void ChangeSelect(bool state)
            {
                DataPoint.Selected = state;
                _selectedInput.Checked = state;
            }
            

        }


        public HTMLDivElement ContendPane { set; get; }

        //public TrendWindow.TrendPlotDataPoint[] SelectedDataPoints
        //{
        //    get
        //    {
        //        List< TrendWindow.TrendPlotDataPoint> selectedDataPoints = new List<TrendWindow.TrendPlotDataPoint>();
        //        foreach (TrendDataRow movableTableRow in _dataTable.Rows)
        //        {
        //            if(movableTableRow.DataPoint.Selected)
        //        }


        //        return _dataPoints.ToArray();
        //    }
        //}

        public Action ChangeDatabase;
        public Action GoNext;
        public Action LoadUserSeries;

        public MainWindow MainWindow { set; get; }
        public IServerConnection ServerConnection { set; get; }

        private MovableTable _dataTable;
        //private List<TrendWindow.TrendPlotDataPoint> _dataPoints = new List<TrendWindow.TrendPlotDataPoint>();

        private int _selectionCount;
        private HTMLInputElement _allDataCount;
        private HTMLInputElement _selectedDataCount;

        public TrendDataSelectPoints()
        {
            ContendPane = new HTMLDivElement()
            {
                Id = "trend_data_select_pane"
            };


            _dataTable = new MovableTable("trend_data_select_data", "mvtbl_table");
            _dataTable.AllowResizeColumns();

            _dataTable.AllowSelectColumns();
            _dataTable.AllowSortColumns();
            _dataTable.AddColumn("S", 30);
            _dataTable.AddColumn("Station", 180);
            _dataTable.AddColumn("Datenpunkt", 220);
            _dataTable.AddColumn("Einheit", 60);
            _dataTable.AddColumn("Erster Wert", 160);
            _dataTable.AddColumn("Letzter Wert", 160);
            _dataTable.AddColumn("Farbe", 60);


            HTMLFieldSetElement dataFieldSet = new HTMLFieldSetElement()
            {
                Id = "trend_data_select_fieldset"
            };
            dataFieldSet.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Aufgezeichnete Daten"
            });
            HTMLDivElement dataInnerScrollDiv = new HTMLDivElement()
            {
                ClassName = "fieldset_overflow_div"
            };
            dataInnerScrollDiv.AppendChild(_dataTable.HTMLElement);
            dataFieldSet.AppendChild(dataInnerScrollDiv);


            HTMLDivElement buttonDiv = new HTMLDivElement()
            {
                Id = "trend_data_select_button_pane"
            };

            HTMLButtonElement selectButton = new HTMLButtonElement()
            {
                TextContent = "Auswählen",
                OnClick = @event =>
                {
                    SelectSelected();
                }
            };
            HTMLButtonElement unselectButton = new HTMLButtonElement()
            {
                TextContent = "Abwählen",
                OnClick = @event =>
                {
                    UnselectSelected();
                }
            };
            HTMLButtonElement unselectAllButton = new HTMLButtonElement()
            {
                TextContent = "Alle Abwählen",
                OnClick = @event =>
                {
                    UnselectAll();
                }
            };


            HTMLButtonElement selectDbButton = new HTMLButtonElement()
            {
                TextContent = "Datenbank auswählen",
                Id = "trend_data_select_db_button",
                OnClick = @event =>
                {
                    ChangeDatabase();
                }
            };

            HTMLButtonElement loadButton = new HTMLButtonElement()
            {
                TextContent = "Laden",
                Id = "trend_data_select_load_button",
                OnClick = @event =>
                {
                    LoadUserSeries();
                }
            };


            HTMLButtonElement nextButtonElement = new HTMLButtonElement()
            {
                TextContent = "Weiter",
                Id = "trend_data_select_next_button",
                OnClick = @event =>
                {
                    GoNext();
                }
            };
            buttonDiv.AppendChild(selectButton);
            buttonDiv.AppendChild(unselectButton);
            buttonDiv.AppendChild(unselectAllButton);
            buttonDiv.AppendChild(selectDbButton);
            buttonDiv.AppendChild(loadButton);
            buttonDiv.AppendChild(nextButtonElement);


            HTMLDivElement dataCountPane = new HTMLDivElement()
            {
                Id = "trend_data_select_data_count_pane"
            };

            HTMLSpanElement selectedData = new HTMLSpanElement()
            {
                TextContent = "Ausgewählte Daten:",
                Id = "trend_data_select_data_sel"

            };
            _selectedDataCount = new HTMLInputElement()
            {
                Type = InputType.Text,
                Disabled = true,
                Value = "0"
            };
            selectedData.AppendChild(_selectedDataCount);

            HTMLSpanElement allData = new HTMLSpanElement()
            {
                TextContent = "Daten Insgesamt:",
                Id = "trend_data_select_data_all"
            };
            _allDataCount = new HTMLInputElement()
            {
                Type = InputType.Text,
                Disabled = true,
                Value =  "0"
            };
            allData.AppendChild(_allDataCount);


            dataCountPane.AppendChild(selectedData);
            dataCountPane.AppendChild(allData);


            ContendPane.AppendChild(dataCountPane);
            ContendPane.AppendChild(dataFieldSet);
            ContendPane.AppendChild(buttonDiv);


            jQuery.Select(_dataTable.HTMLElement).On("remove", (Action)(() =>
            {
                _dataTable.Dispose();
            }));
        }

        public void ToggleExportButton(bool active)
        {

        }


        private void SelectSelected()
        {
            foreach (TrendDataRow row in _dataTable.SelectedRows)
            {
                row.ChangeSelect(true);
            }
        }

        private void UnselectSelected()
        {
            foreach (TrendDataRow row in _dataTable.SelectedRows)
            {
                row.ChangeSelect(false);
            }
        }

        private void UnselectAll()
        {
            foreach (TrendDataRow row in _dataTable.Rows)
            {
                row.ChangeSelect(false);
            }
        }

        public void SetSelectedCount(int count)
        {
            _selectionCount = count;
        }

        public void FillTableList(TrendWindow.TrendPlotDataPoint[] datapoints)
        {

            //random here for better random
            //Random r = new Random();
            //foreach (var trendDataPoint in datapoints)
            //{
            //    var point = new TrendWindow.TrendPlotDataPoint(trendDataPoint);
            //    point.Color = Functions.HslToRgb((r.Next(23)*15), 1, 1);
            //}

            _allDataCount.Value = datapoints.Length.ToString();
            this._dataTable.BeginUpdate();
            _dataTable.ClearRows();

            foreach (var trendPlotDataPoint in datapoints)
            {
                var row = new TrendDataRow(trendPlotDataPoint);
               
                _dataTable.AddRow(row);
            }
            _dataTable.EndUpdate();
            

        }
        


        public void Close()
        {
            this._dataTable.Dispose();
        }
    }
}
