using System;
using System.Collections.Generic;
using System.Diagnostics;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    /// <summary>
    /// Gui fox selecting a database
    /// </summary>
    public class TrendDataSelectDatabase
    {

        private class SelectDatabaseDatatableRow : MovableTable.MovableTableRow
        {
            public TrendDatabase Database { private set; get; }

            public string DbName
            {
                get { return this.Database.Name; }
            }

            //public bool IsSelected { private set; get; }
            private HTMLInputElement _optionElement;

            public SelectDatabaseDatatableRow(TrendDatabase database)
            {
                Database = database;

                _optionElement = new HTMLInputElement()
                {
                    Type = InputType.Radio,
                    Name = "selected_db"
                };
                _optionElement.OnChange += @event =>
                {
                    IsSelected = _optionElement.Checked;
                };
                this.AddCell(new MovableTable.MovableTableCell(_optionElement));
                this.AddCell(database.Description);
                this.AddCell(Functions.DatetimeToLocalDateTimeString(database.Start));
                this.AddCell(Functions.DatetimeToLocalDateTimeString(database.End));

            }
        }

        public string SelectedDatabaseName
        {
            get
            {
                //var value = _userSelection.ChildNodes[_userSelection.SelectedIndex].NodeValue;
                //Console.WriteLine("user: " + _userSelection.SelectedOptions.FirstOrDefault().Value);
                //return value;
                string name = "";
                foreach (SelectDatabaseDatatableRow row in _databseSelectTable.Rows)
                {
                    if (row.IsSelected)
                    {
                        name = row.DbName;
                        break;
                    }
                }
                return name;
            }
        }
        public MainWindow MainWindow { set; get; }
        public IServerConnection ServerConnection { set; get; }


        private ToolWindow _toolWindow { set; get; }
        

        private MovableTable _databseSelectTable;

        public Action HasSelectedDatabase;
        

        public TrendDataSelectDatabase(string selectedDatabase)
        {

            _toolWindow = new ToolWindow(MainWindow, "Datenbank auswählen", "selectdb_window", 600, 400);

            _toolWindow.MakeCloseable();
            _toolWindow.Closed += () =>
            {
                Overlay.Hide();
            };




            HTMLFieldSetElement selectFieldset = new HTMLFieldSetElement()
            {
                Id = "trend_select_database_fieldest"
            };
            selectFieldset.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Datenbanken"
            });

            _databseSelectTable = new MovableTable("trend_select_database_table", "mvtbl_table");
            _databseSelectTable.AddColumn(" ", 20);
            _databseSelectTable.AddColumn("Name", 400);
            _databseSelectTable.AddColumn(new MovableTable.MovableTableColumn("Beginn")
            {
                ColumnType = MovableTable.MovableTableColumn.ColumnTypes.Date
            });
            _databseSelectTable.AddColumn(new MovableTable.MovableTableColumn("Ende")
            {
                ColumnType = MovableTable.MovableTableColumn.ColumnTypes.Date
            });

            HTMLFormElement wrapperForm = new HTMLFormElement();
            wrapperForm.AppendChild(_databseSelectTable.HTMLElement);

            HTMLDivElement scrollDiv = new HTMLDivElement
            {
                ClassName = "fieldset_overflow_div"
            };

            scrollDiv.AppendChild(wrapperForm);
            selectFieldset.AppendChild(scrollDiv);





            HTMLButtonElement buttonOk = new HTMLButtonElement()
            {
                TextContent = "Auswählen",
                OnClick = @event =>
                {
                    if (HasSelectedDatabase != null)
                    {
                        HasSelectedDatabase();
                    }
                    _toolWindow.CloseWindow();
                }
            };

            HTMLButtonElement buttonCancel = new HTMLButtonElement()
            {
                TextContent = "Abbrechen",
                OnClick = @event =>
                {
                    _toolWindow.CloseWindow();
                }
            };

            HTMLDivElement buttonpane = new HTMLDivElement()
            {
                Id = "trend_select_database_buttons"
            };
            buttonpane.AppendChild(buttonOk);
            buttonpane.AppendChild(buttonCancel);
            
            _toolWindow.GetContendArea().AppendChild(selectFieldset);
            _toolWindow.GetContendArea().AppendChild(buttonpane);

            Overlay.Show();


            Functions.CenterWindow(this._toolWindow.GetHTMLObject());

            _toolWindow.Closed += () =>
            {
                _databseSelectTable.Dispose();
            };

        }
        

        public void AddDatabases(IEnumerable< TrendDatabase> databases)
        {
            
            _databseSelectTable.BeginUpdate();
            _databseSelectTable.ClearRows();

            foreach (var database in databases)
            {
                //Console.WriteLine(database.name);
                _databseSelectTable.AddRow(new SelectDatabaseDatatableRow(database));
            }
            _databseSelectTable.EndUpdate();
        }

    }
}
