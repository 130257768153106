using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class ChangeValueWindow
    {
        private ToolWindow _toolWindow;
        private IServerConnection _serverConnection;
        
        private HTMLDivElement _newValueContainer;
        private HTMLInputElement _newValueNormal;
        private HTMLSelectElement _newValueText;

        private HTMLButtonElement _okButton;
        private HTMLButtonElement _cancelButton;

        private HTMLDivElement _valueInfo;
        private HTMLDivElement _oldValue;
        private HTMLLegendElement _stationInfo;

        private HTMLTableElement _historyTable;

        private bool _isText = false;

        private readonly string _windowId;
        private readonly int _elementId;

        public ChangeValueWindow(MainWindow mainWindow, IServerConnection serverConnection, string windowId, int elementId)
        {
            _serverConnection = serverConnection;
            _windowId = windowId;
            _elementId = elementId;
            
            Overlay.Show();

            _toolWindow = new ToolWindow(mainWindow, "Wert ändern", "changevaluebox", 450, 300);
            _toolWindow.MakeCloseable();
            _toolWindow.Closed += WindowClosed;
            Window.OnKeyDown += OnKeyDown;
            mainWindow.BringWindowToFront(_toolWindow);


            #region infofieldset topleft
            HTMLFieldSetElement valInfoFieldset = new HTMLFieldSetElement()
            {
                ClassName = "changevalue_info_box_fieldset"
            };
            _stationInfo = new HTMLLegendElement()
            {
                ClassName = "changevalue_info_box_legend",
                TextContent = "S: - A:"
            };

            valInfoFieldset.AppendChild(_stationInfo);

            //infotable
            HTMLTableElement infoboxtable = new HTMLTableElement()
            {
                Style =
                {
                    Width = "300px"
                }
            };
            
            //##### ROW 1
            var infoboxrow1 = infoboxtable.InsertRow();
            infoboxrow1.ClassName = "changevalue_info_box_info_row";
            //CELL 1
            var infoboxrow1cell1 = infoboxrow1.InsertCell();
            infoboxrow1cell1.TextContent = "Anlage:";
            infoboxrow1cell1.SetAttribute("align", "right");
            //CELL 1-3
            var infoboxrow1cell2 = infoboxrow1.InsertCell();
            infoboxrow1cell2.SetAttribute("colspan", "3");
            infoboxrow1cell2.Style.SetProperty("border", "1px solid black");
            infoboxrow1cell2.ColSpan = 3;
            
            _valueInfo = new HTMLDivElement()
            {
                ClassName = "changevalue_info_box_info",
                TextContent = "Warte..."
            };
            infoboxrow1cell2.AppendChild(_valueInfo);

            //##### ROW 2
            var infoboxrow2 = infoboxtable.InsertRow();
            //Cell 1
            var infoboxrow2cell1 = infoboxrow2.InsertCell();
            infoboxrow2cell1.TextContent = "Alt:";
            infoboxrow2cell1.SetAttribute("align", "right");

            //Cell 2
            var infoboxrow2cell2 = infoboxrow2.InsertCell();
            _oldValue = new HTMLDivElement()
            {
                ClassName = "changevalue_info_box_oldvalue",
                TextContent = "---"
            };
            infoboxrow2cell2.AppendChild(_oldValue);

            //Cell 3
            var infoboxrow2cell3 = infoboxrow2.InsertCell();
            infoboxrow2cell3.TextContent = "Neu:";
            infoboxrow2cell3.SetAttribute("align", "right");

            //Cell 4
            var infoboxrow2cell4 = infoboxrow2.InsertCell();
            _newValueContainer = new HTMLDivElement()
            {
                ClassName = "changevalue_info_box_newvalue",
            };
            infoboxrow2cell4.AppendChild(_newValueContainer);

            valInfoFieldset.AppendChild(infoboxtable);
            #endregion

            #region submitdiv topright

            HTMLDivElement submitDivContainer = new HTMLDivElement()
            {
                ClassName = "chnagevalue_submit_buttons"
            };

            _okButton = new HTMLButtonElement()
            {
                ClassName = "changevalue_botton_ok",
                TextContent = "OK",
                Value = "ok",
                Name = "chnagevalue_button",
                OnClick = @event =>
                {
                    UpdateVar();
                }
            };

            _cancelButton = new HTMLButtonElement()
            {
                ClassName = "changevalue_botton_cancel",
                TextContent = "Abbrechen",
                Value = "cancel",
                Name = "chnagevalue_button",
                OnClick = @event =>
                {
                    CloseWindow();
                }
            };

            submitDivContainer.AppendChild(_okButton);
            submitDivContainer.AppendChild(_cancelButton);

            #endregion

            #region history down

            HTMLFieldSetElement historyFieldset = new HTMLFieldSetElement()
            {
                ClassName = "changevalue_history_fieldset",
                Title = "Letzte Änderungen"
            };

            _historyTable = new HTMLTableElement()
            {
                ClassName = "changevalue_history_table"
            };

            var historyTableHeadRow = _historyTable.CreateTHead().InsertRow();

            historyTableHeadRow.InsertCell().TextContent = "Datum";
            historyTableHeadRow.InsertCell().TextContent = "alter Wert";
            historyTableHeadRow.InsertCell().TextContent = "neuer Wert";
            historyTableHeadRow.InsertCell().TextContent = "Benutzer";
            historyFieldset.AppendChild(_historyTable);

            #endregion


            _toolWindow.GetContendArea().AppendChild(valInfoFieldset);
            _toolWindow.GetContendArea().AppendChild(submitDivContainer);
            _toolWindow.GetContendArea().AppendChild(historyFieldset);
            
            _serverConnection.SystemPictureGetValueInfo(_windowId, _elementId, (Action<ControlPictureChangeValueInfo>)Action, (Action<string>)(e => { }));
        }

        private void OnKeyDown(KeyboardEvent keyboardEvent)
        {
            if (keyboardEvent.KeyCode == 27)
            {
                CloseWindow();
            }
        }

        private void UpdateVar()
        {
            string newValue = "";

            if (_isText)
            {
                newValue = _newValueText.Value;
            }
            else
            {
                newValue = _newValueNormal.Value;
            }

            _serverConnection.SystemPictureSetValue(_windowId, _elementId, newValue,  (Action<bool>)((b) => { CloseWindow(); }),
(Action<string>)(                e =>
                {

                    MessageBox.Show("Wert konnte nicht geändert werden!", MessageBox.MessageBoxTypes.Error, 5000, this._toolWindow.GetHTMLObject() as HTMLDivElement);
                }
)            );
        }
        

        private void Action(ControlPictureChangeValueInfo changeValueInfo)
        {
            _valueInfo.TextContent = changeValueInfo.Description;
            _oldValue.TextContent = changeValueInfo.Value;
            _stationInfo.TextContent = changeValueInfo.Info;

            if (changeValueInfo.IsText)
            {
                _isText = true;

                _newValueText = new HTMLSelectElement();
                foreach (var text in changeValueInfo.Texts)
                {
                    _newValueText.AppendChild(new HTMLOptionElement()
                    {
                        Text = text,
                        Value = text
                    });
                }
                _newValueText.OnKeyPress = @event =>
                {
                    if (@event.As<KeyboardEvent>().KeyCode == 13)
                    {
                        UpdateVar();
                    }
                };
                _newValueText.Value = changeValueInfo.Value;
                _newValueContainer.AppendChild(_newValueText);
            }
            else
            {
                _isText = false;
                _newValueNormal = new HTMLInputElement()
                {
                    Value = changeValueInfo.Value
                };
                _newValueNormal.OnKeyPress = @event =>
                {
                    if (@event.As<KeyboardEvent>().KeyCode == 13)
                    {
                        UpdateVar();
                    }
                };
                _newValueContainer.AppendChild(_newValueNormal);
            }

            foreach (var historyInfo in changeValueInfo.History)
            {
                var newRow = _historyTable.InsertRow();
                newRow.InsertCell().AppendChild(new Text(Functions.DatetimeToLocalDateTimeString(historyInfo.Time)));
                newRow.InsertCell().TextContent = historyInfo.ValueBefore;
                newRow.InsertCell().TextContent = historyInfo.ValueAfter;
                newRow.InsertCell().TextContent = historyInfo.UserName;
            }

        }


        private void WindowClosed()
        {
            Window.OnKeyDown -= OnKeyDown;
            Overlay.Hide();
        }


        private void CloseWindow()
        {
            _toolWindow.CloseWindow();
        }
    }
}
