using System.Threading.Tasks;
using Bridge.Html5;
using VisuPlusWebApp.Classes;
using VisuPlusWebApp.Classes.Types;

namespace VisuPlusWebApp.WebView.Controls
{
    public class ToolWindow
    {
        public class ResizedEvent
        {
            public float ScaleX { set; get; }
            public float ScaleY { set; get; }

            public ResizedEvent(float scaleX, float scaleY)
            {
                ScaleX = scaleX;
                ScaleY = scaleY;
            }
        }

        public string Id { set; get; }

        public string Text
        {
            set { _headDivText.TextContent = value; }
            get { return _headDivText.TextContent; }
        }



        protected MainWindow _mainWindow;

        private HTMLDivElement _windowDiv;
        private HTMLDivElement _headDiv;
        private HTMLDivElement _contendDiv;
        private HTMLAnchorElement _closeLink;

        private HTMLDivElement _resizeBorderRight;
        private HTMLDivElement _resizeBorderBottom;
        private HTMLDivElement _resizeBorderBottomRight;

        private HTMLSpanElement _headDivText;

        public System.Action Closed;
        public System.Action Resized;
        public System.Action ResizedFinished;

        public Point Position
        {
            get
            {
                return new Point()
                {
                    X = _windowDiv.OffsetLeft,
                    Y = _windowDiv.OffsetTop
                };
            }
            set
            {
                _windowDiv.Style.SetProperty("top", value.Y + "px");
                _windowDiv.Style.SetProperty("left", value.X + "px");
            }
        }

        public Size WindowSize
        {
            get
            {
                return new Size()
                {
                    Width = _windowDiv.ClientWidth,
                    Height = _windowDiv.ClientHeight
                };
            }
            set
            {
                _windowDiv.Style.SetProperty("height", value.Height + "px");
                _windowDiv.Style.SetProperty("width", value.Width + "px");
            }
        }

        private bool _isDraggable = false;
        private bool _isResizable = false;
        private bool _resizingHorizontal = false;
        private bool _resizingVertical = false;
        private bool _dragging = false;
        private Classes.Types.Point _draggingMouseStart;
        private Classes.Types.Size _resizingStartSize;
        private bool _isClosable = true;

        private Classes.Types.Size _minimumSize = Size.FromWH(200, 200);
        

        public ToolWindow(MainWindow mainWindow, string text, string id, int width, int height)
        {
            this._mainWindow = mainWindow;
            this.Id = id;
            

            _windowDiv = new HTMLDivElement()
            {
                Id = Id,
                ClassName = "draggableWindow",
                Style =
                {
                    Width = width + "px",
                    Height = height + "px",
                    Overflow = Overflow.Hidden
                }
            };

            _headDiv = new HTMLDivElement()
            {
                ClassName = "draggableWindowHead"
            };

            _contendDiv = new HTMLDivElement()
            {
                ClassName = "draggableWindowContend"
            };

            _headDivText = new HTMLSpanElement();
            _headDiv.AppendChild(_headDivText);

            this.Text = text;
            _windowDiv.OnMouseDown += WindowMouseDown;
            _mainWindow.MainWindowDiv.OnMouseUp += HeadMouseUp;

            _windowDiv.AppendChild(_headDiv);
            _windowDiv.AppendChild(_contendDiv);


            _mainWindow.AddWindow(this);
            //_mainWindow.BringWindowToFront(this);
        }
        

        public void MakeResizable()
        {
            _isResizable = true;

            _resizeBorderBottom = new HTMLDivElement()
            {
                ClassName = "draggableWindowResizeBorderBottom"
            };
            _resizeBorderRight = new HTMLDivElement()
            {
                ClassName = "draggableWindowResizeBorderRight"
            };
            _resizeBorderBottomRight = new HTMLDivElement()
            {
                ClassName = "draggableWindowResizeBorderBottomRight"
            };


            _mainWindow.MainWindowDiv.OnMouseMove += OnMouseMove;
            _resizeBorderBottom.OnMouseDown += ResizingVertialStart;
            _resizeBorderRight.OnMouseDown += ResizingHorizontalStart;
            _resizeBorderBottomRight.OnMouseDown += ResizingHorizontalStart;
            _resizeBorderBottomRight.OnMouseDown += ResizingVertialStart;


            _windowDiv.AppendChild(_resizeBorderBottom);
            _windowDiv.AppendChild(_resizeBorderRight);
            _windowDiv.AppendChild(_resizeBorderBottomRight);
        }

        public void MakeDraggable()
        {
            _isDraggable = true;
            _headDiv.OnMouseDown += HeadMouseDown;

        }

        public void SetMinimumSize(int width, int height)
        {
            _minimumSize.Height = height;
            _minimumSize.Width = width;
        }

        public void MakeCloseable()
        {
            _closeLink = new HTMLAnchorElement()
            {
                ClassName = "draggableWindowCloseLink",
                OnClick = CloseWindow,
                TextContent = "X",
                Href = "#"
            };
            _headDiv.AppendChild(_closeLink);

        }

        public void MakeHidable()
        {
            _closeLink = new HTMLAnchorElement()
            {
                ClassName = "draggableWindowCloseLink",
                OnClick = HideWindow,
                TextContent = "X",
                Href = "#"
            };
            _isClosable = false;
            _headDiv.AppendChild(_closeLink);
        }

        public void SetBounds(int height, int width, int x, int y)
        {
            _windowDiv.Style.SetProperty("left", x + "px");
            _windowDiv.Style.SetProperty("top", y + "px");
            _windowDiv.Style.SetProperty("width", width + "px");
            _windowDiv.Style.SetProperty("height", height + "px");
        }



        private void ResizingVertialStart(MouseEvent<HTMLDivElement> mouseEvent)
        {
            _draggingMouseStart = Functions.GetMousePosition(_mainWindow.ContendArea, mouseEvent);
            _draggingMouseStart.X = _draggingMouseStart.X - _windowDiv.ClientLeft;
            _draggingMouseStart.Y = _draggingMouseStart.Y - _windowDiv.ClientTop;
            _resizingStartSize = Size.FromWH(_windowDiv.ClientWidth, _windowDiv.ClientHeight);
            _resizingVertical = true;
        }


        private void ResizingHorizontalStart(MouseEvent<HTMLDivElement> mouseEvent)
        {
            _draggingMouseStart = Functions.GetMousePosition(_mainWindow.ContendArea, mouseEvent);
            _draggingMouseStart.X = _draggingMouseStart.X - _windowDiv.ClientLeft;
            _draggingMouseStart.Y = _draggingMouseStart.Y - _windowDiv.ClientTop;
            _resizingStartSize = Size.FromWH(_windowDiv.ClientWidth, _windowDiv.ClientHeight);
            _resizingHorizontal = true;
        }


        private void OnMouseMove(MouseEvent<HTMLDivElement> mouseEvent)
        {
            if (_dragging)
            {
                Classes.Types.Point newPos = Functions.GetMousePosition(_mainWindow.ContendArea, mouseEvent);
                
                newPos.X -= _draggingMouseStart.X;
                newPos.Y -= _draggingMouseStart.Y;
                if (newPos.X < 0)
                {
                    newPos.X = 0;
                }
                if (newPos.Y < 0)
                {
                    newPos.Y = 0;
                }


                _windowDiv.Style.SetProperty("left", newPos.X+ "px");
                _windowDiv.Style.SetProperty("top", newPos.Y + "px");
            }
            if(_resizingHorizontal)
            {
                Classes.Types.Point newPos = Functions.GetMousePosition(_mainWindow.ContendArea, mouseEvent);

                int newWidth = _resizingStartSize.Width + (newPos.X - _draggingMouseStart.X);

                if (newWidth < _minimumSize.Width)
                {
                    newWidth = _minimumSize.Width;
                }

                _windowDiv.Style.SetProperty("width", newWidth + "px");
                if (this.Resized != null)
                {
                    this.Resized();
                }
            }
            if (_resizingVertical)
            {
                Classes.Types.Point newPos = Functions.GetMousePosition(_mainWindow.ContendArea, mouseEvent);

                int newHeight = _resizingStartSize.Height + (newPos.Y - _draggingMouseStart.Y);

                if (newHeight < _minimumSize.Height)
                {
                    newHeight = _minimumSize.Height;
                }
                _windowDiv.Style.SetProperty("height", newHeight + "px");

                if (this.Resized != null)
                {
                    this.Resized();

                }
            }
        }

        private void HeadMouseDown(MouseEvent<HTMLDivElement> mouseEvent)
        {
            _headDiv.Style.Cursor = Cursor.Move;
            _draggingMouseStart = Functions.GetMousePosition(_headDiv, mouseEvent);
            _draggingMouseStart.X =  _draggingMouseStart.X - _windowDiv.ClientLeft;
            _draggingMouseStart.Y =  _draggingMouseStart.Y - _windowDiv.ClientTop;
            _dragging = true;
            
        }
        private void HeadMouseUp(MouseEvent<HTMLDivElement> mouseEvent)
        {
            _dragging = false;
            if ((_resizingHorizontal || _resizingVertical) && ResizedFinished != null)
            {
                Task.Run((System.Action)(() => ResizedFinished()));
            }
            _resizingHorizontal = false;
            _resizingVertical = false;

            _headDiv.Style.Cursor = Cursor.Default;
            
        }

        private void WindowMouseDown(MouseEvent<HTMLDivElement> mouseEvent)
        {

            _mainWindow.BringWindowToFront(this);


        }


        

        private void CloseWindow(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            CloseWindow();
        }


        public void CloseWindow()
        {
            if(!_isClosable) return;
            
            if (_isResizable)
            {
                _resizeBorderBottom.OnMouseDown -= ResizingVertialStart;
                _resizeBorderRight.OnMouseDown -= ResizingHorizontalStart;
                _resizeBorderBottomRight.OnMouseDown -= ResizingHorizontalStart;
                _resizeBorderBottomRight.OnMouseDown -= ResizingVertialStart;
            }
            if (_isDraggable)
            {
                _headDiv.OnMouseDown -= HeadMouseDown;
            }


            _headDiv.OnMouseDown -= HeadMouseDown;
            _windowDiv.OnMouseDown -= WindowMouseDown;
            _mainWindow.MainWindowDiv.OnMouseUp -= HeadMouseUp;
            _mainWindow.MainWindowDiv.OnMouseMove -= OnMouseMove;
            

            Closing();
            
            if (Closed != null)
            {
                  Closed();
            }
        }

        private void HideWindow(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            this._windowDiv.Style.SetProperty("display", "none");
        }

        private void Closing()
        {
            _mainWindow.RemoveWindow(this);

        }
        
        public HTMLDivElement GetHTMLObject()
        {
            return _windowDiv;
        }

        public HTMLDivElement GetContendArea()
        {
            return _contendDiv;
        }
    }


}