using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class TrendWindow
    {
        /// <summary>
        /// inner class with additional info and color
        /// </summary>
        public class TrendPlotDataPoint
        {
            public TrendIndexItem IndexItem { set; get; }
            public string Color { set; get; }
            public double Increase { set; get; }
            public bool Selected { set; get; }

            public Action SelectionChanged;
            
            public TrendPlotDataPoint(TrendIndexItem index)
            {
                IndexItem = index;
                this.Increase = 0;
                this.Selected = false;

            }
        }

        

        private ToolWindow _toolWindow { set; get; }
        private MainWindow _mainWindow;

        private IServerConnection _serverConnection;

        private List<TrendPlotDataPoint> _data = new List<TrendPlotDataPoint>(); 

        private string _databaseName = "actual";

        private HTMLDivElement _contendPane;

        private HTMLDivElement _loadWindow;

        private DateTime _selectedStartTime;
        private DateTime _selectedEndTime;

        private ITrendPane _openPane = null;

        public TrendWindow(MainWindow mainWindow, IServerConnection serverConnection)
        {
            _serverConnection = serverConnection;
            _mainWindow = mainWindow;

            _toolWindow = new ToolWindow(mainWindow, "Trend", "trend_window", 1000, 600);
            _toolWindow.SetMinimumSize(550, 400);
            _toolWindow.MakeCloseable();
            _toolWindow.MakeResizable();
            _toolWindow.MakeDraggable();
            _contendPane = _toolWindow.GetContendArea() as HTMLDivElement;
            _loadWindow = new HTMLDivElement()
            {
                Id = "trend_load_window"
            };
            _contendPane.AppendChild(_loadWindow);
            GetDataListUpdate();
        }


        private List<TrendPlotDataPoint> GetSelectedData()
        {
            List<TrendPlotDataPoint> selectedData = new List<TrendPlotDataPoint>();
            foreach (var trendPlotDataPoint in _data)
            {
                if (trendPlotDataPoint.Selected)
                {
                    selectedData.Add(trendPlotDataPoint);
                }
            }
            return selectedData;
        }

        private void GetDataListUpdate()
        {
           ShowLoadWindow();
            _serverConnection.TrendGetDataList(_databaseName, (Action<TrendIndexItem[]>)FillListData, (Action<string>)(err => { }));
            ShowTrendSelectData();
        }

        private void ShowLoadWindow()
        {
            _loadWindow.TextContent = "Lade Daten...";
            jQuery.Select(_loadWindow).Show();
        }

        private void UpdateLoadInfo(string text)
        {
            _loadWindow.InnerHTML = "Lade Daten... <br>" + text;
        }

        private void HideLoadInfo()
        {
            jQuery.Select(_loadWindow).Hide();
        }

        private void FillListData(IEnumerable<TrendIndexItem> list)
        {
            //random here for better random
            _data.Clear();
            Random r = new Random();
            foreach (var trendDataPoint in list)
            {
                var point = new TrendPlotDataPoint(trendDataPoint);
                point.Color = Functions.HslToRgb((r.Next(23) * 15), 1, 1);
                _data.Add(point);
            }
            ShowTrendSelectData();
        }

        private void ShowTrendSelectData()
        {
            if (_openPane != null)
            {
                _openPane.Close();
                jQuery.Select(_openPane.ContendPane).Remove();
                _openPane = null;
            }
            var trendSelectDataPane = new TrendDataSelectPoints();
            _openPane = trendSelectDataPane;
            _contendPane.AppendChild(trendSelectDataPane.ContendPane);
            trendSelectDataPane.ChangeDatabase += ChangeDatabaseDatabase;
            trendSelectDataPane.GoNext += ShowTrendSelectTime;
            trendSelectDataPane.LoadUserSeries += LoadUserSeriesWindow;
            trendSelectDataPane.SetSelectedCount(GetSelectedData().Count);
            trendSelectDataPane.FillTableList(_data.ToArray());
            trendSelectDataPane.MainWindow = _mainWindow;
            trendSelectDataPane.ServerConnection = _serverConnection;
            HideLoadInfo();
        }



        private void ShowTrendSelectTime()
        {
            var selectedData = GetSelectedData();
            if (selectedData.Count == 0)
            {
                MessageBox.Show("Keine Daten ausgewählt", MessageBox.MessageBoxTypes.Error, 2000, _contendPane);
                return;
            }
            //find min and max
            DateTime minDate = DateTime.MaxValue;
            DateTime maxDate  = DateTime.MinValue;
            foreach (var trendPlotDataPoint in selectedData)
            {
                if (trendPlotDataPoint.IndexItem.ReadFirst > maxDate )
                {
                    maxDate = trendPlotDataPoint.IndexItem.ReadFirst;
                }
                if(trendPlotDataPoint.IndexItem.ReadLast > maxDate)
                {
                    maxDate = trendPlotDataPoint.IndexItem.ReadLast;
                }
                if (trendPlotDataPoint.IndexItem.ReadFirst < minDate)
                {
                    minDate = trendPlotDataPoint.IndexItem.ReadFirst;
                }
                if (trendPlotDataPoint.IndexItem.ReadLast < minDate)
                {
                    minDate = trendPlotDataPoint.IndexItem.ReadLast;
                }
            }


            if (_openPane != null)
            {
                _openPane.Close();
                jQuery.Select(_openPane.ContendPane).Remove();
                _openPane = null;
            }
            var selectTimePane = new TrendDataSelectTime(minDate, maxDate, selectedData.ToArray(), _databaseName);
            _openPane = selectTimePane;
            _contendPane.AppendChild(selectTimePane.ContendPane);

            selectTimePane.GoNext += () =>
            {
                _selectedEndTime = selectTimePane.SelectRangeEnd;
                _selectedStartTime = selectTimePane.SelectRangeStart;
                ShowTrendPlot();
            };
            selectTimePane.Export += () =>
            {
                _selectedEndTime = selectTimePane.SelectRangeEnd;
                _selectedStartTime = selectTimePane.SelectRangeStart;
                ExportData();
            };
            selectTimePane.GoBack += ShowTrendSelectData;

            selectTimePane.MainWindow = _mainWindow;
            selectTimePane.ServerConnection = _serverConnection;
            //selectTimePane.ActivateDatetimePicker(minDate,maxDate);

        }

        private void ExportData()
        {
            _openPane.ToggleExportButton(false);
            var selectedData = GetSelectedData();

            int[] ids = new int[selectedData.Count];
            for (int i = 0; i < ids.Length; i++)
            {
                ids[i] = selectedData[i].IndexItem.Id;
            }
            
            _serverConnection.TrendGetDataExport(this._databaseName, new TrendSelection()
            {
                 Start = _selectedStartTime,
                 End = _selectedEndTime,
                 SelectedIds = ids
            },(Action<Blob>)SaveExportData,(Action<string>)(err => { MessageBox.Show("Fehler beim Export!<br>" + err, MessageBox.MessageBoxTypes.Error, 5000, this._contendPane); }));
        }

        private void SaveExportData(Blob blob)
        {
            string url = Bridge.Script.Call<string>("window.URL.createObjectURL", blob);

            var dlLin = new HTMLAnchorElement()
            {
                Href = url,
                Download = "trendexport.xls",

            };
            dlLin.Style.Display = Display.None;
            Document.Body.AppendChild(dlLin);
            dlLin.Click();
            Bridge.Script.Call("window.URL.revokeObjectURL", url);
            dlLin.Remove();

            _openPane.ToggleExportButton(true);
        }

        private void ShowTrendPlot()
        {
            var selectedData = GetSelectedData();

            int[] ids = new int[selectedData.Count];
            for (int i = 0; i < ids.Length; i++)
            {
                ids[i] = selectedData[i].IndexItem.Id;
            }

            if (_openPane != null)
            {
                _openPane.Close();
                jQuery.Select(_openPane.ContendPane).Remove();
                _openPane = null;
            }
            ShowLoadWindow();
            var trendPlotPane = new TrendShowPlot(GetSelectedData().ToArray());
            _openPane = trendPlotPane;
            _contendPane.AppendChild(trendPlotPane.ContendPane);
            trendPlotPane.MainWindow = _mainWindow;
            trendPlotPane.ServerConnection = _serverConnection;

            _toolWindow.ResizedFinished = () => trendPlotPane.Resize();

            trendPlotPane.GoBack += ShowTrendSelectTime;

            _serverConnection.TrendGetDataData(this._databaseName, new TrendSelection()
            {
                Start = _selectedStartTime,
                End = _selectedEndTime,
                SelectedIds = ids
            },
(Action<int>)(            percent =>
            {
                UpdateLoadInfo("(" + percent + " %)");
            }),
(Action<TrendDataSeries[]>)(                data =>
                {
                    trendPlotPane.ShowData(data);
                    HideLoadInfo();
                }), 
(Action<string>)(            err => { }));
            
        }

        private void LoadUserSeries(TrendUserSelection selection, IEnumerable<TrendUserSelectionItem> items)
        {
            /*if(_databaseName != selection.Database)
            {
                _databaseName = selection.Database;
            }*/

            TrendUserSelectionItem matchedItem = null;
            foreach(var point in _data)
            {
                point.Selected = false;
                matchedItem = System.Linq.Enumerable.Where<TrendUserSelectionItem>(items,(Func<TrendUserSelectionItem,bool>)(item => item.TrendIndexId == point.IndexItem.Id)).FirstOrDefault();
                if (matchedItem != null)
                {
                    point.Selected = true;
                    point.Increase =  matchedItem.Increase;
                    point.Color = matchedItem.Color;
                }              

            }

            if (selection.IsFreeRange)
            {
                _selectedEndTime = selection.FreeRangeEnd;
                _selectedStartTime = selection.FreeRangeStart;
            }
            else
            {
                _selectedEndTime = DateTime.Now;
                _selectedStartTime = DateTime.Now - TimeSpan.FromSeconds(selection.FixedRangeInterval);
            }

            ShowTrendPlot();

        }

        private void LoadUserSeriesWindow()
        {

            TrendLoadOverlay loadOverlay = new TrendLoadOverlay(_mainWindow, _serverConnection);
            loadOverlay.FillTable();
            loadOverlay.DialogFinished += (ok) => {
                if (ok)
                {
                    _serverConnection.TrendUserSelectionGetById(loadOverlay.SelectedId, (System.Action<RGAVerl.VisuPlus2000net.Models.TrendUserSelection>)(series =>
                    {
                        _serverConnection.TrendUserSelectionItemsGet(loadOverlay.SelectedId, (Action<TrendUserSelectionItem[]>)(items =>
                        {
                            LoadUserSeries(series, items);
                        }),
(Action<string>)(                        error =>
                        {
                            MessageBox.Show("Fehler", MessageBox.MessageBoxTypes.Error, 3000, this._toolWindow.GetContendArea());
                        }));        
                    }),
(Action<string>)(                    error =>
                    {
                        MessageBox.Show("Fehler", MessageBox.MessageBoxTypes.Error, 3000, this._toolWindow.GetContendArea());
                    }));
                }
            };


        }


        private void ChangeDatabaseDatabase()
        {
            var slectDbWindow = new TrendDataSelectDatabase(_databaseName);
            slectDbWindow.ServerConnection = _serverConnection;
            slectDbWindow.MainWindow = _mainWindow;
            slectDbWindow.HasSelectedDatabase += () =>  
            {
                _databaseName = slectDbWindow.SelectedDatabaseName;
                GetDataListUpdate();
            };
            _serverConnection.TrendGetDatabases((Action<TrendDatabase[]>)slectDbWindow.AddDatabases,(Action<string>)(err =>{}));
        }
        
    }
}
