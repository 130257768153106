using System.Collections.Generic;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class FaultListWindowBoxView : IFaultListWindowView
    {

        public HTMLDivElement ViewDivElement { private set; get; }


        private HTMLSelectElement _sortTypeSelect;
        private HTMLSelectElement _sortOrderSelect;
        private HTMLDivElement _innerBoxHeader;
        private HTMLDivElement _innerBoxList;

        private FaultList _faultList;

        private List<FaultListWindowBoxViewItem> _windowItems = new List<FaultListWindowBoxViewItem>();

        private bool _allowQuit;

        public void Init(FaultList faultList)
        {
            this._faultList = faultList;
            _faultList.Updated += Updated;
            _faultList.UpdateAll += UpdateAll;

            foreach (var child in _sortOrderSelect.Children)
            {
               HTMLOptionElement option = child as HTMLOptionElement;
                if(option ==  null)continue;
                if (option.Value == ((int) Settings.FaultSortOrder).ToString())
                {
                    option.Selected = true;
                    break;
                }
            }
            foreach (var child in _sortTypeSelect.Children)
            {
                HTMLOptionElement option = child as HTMLOptionElement;
                if (option == null) continue;
                if (option.Value == ((int) Settings.FaultSortType).ToString())
                {
                    option.Selected = true;
                    break;
                }
            }

            UpdateViewList();

        }
        public void Remove()
        {
            _faultList.Updated -= Updated;
            _faultList.UpdateAll -= UpdateAll;
            //ViewDivElement.Remove();
            ViewDivElement.ParentElement.RemoveChild(ViewDivElement);
        }

        public void SetQuitMode(bool allow)
        {
            _allowQuit = allow;
            UpdateAll();
        }
        public void SetDeleteMode(bool allow)
        {
        }


        public FaultListWindowBoxView()
        {
            ViewDivElement = new HTMLDivElement()
            {
                Id = "faultlistviewbox"
            };
            _sortOrderSelect = new HTMLSelectElement();
            _sortTypeSelect = new HTMLSelectElement();
            _sortOrderSelect.AppendChild(new HTMLOptionElement()
            {
                TextContent = "Aufsteigend",
                Value = ((int)Enums.SortOrder.ASC).ToString()
            });
            _sortOrderSelect.AppendChild(new HTMLOptionElement()
            {
                TextContent = "Absteigend",
                Value = ((int)Enums.SortOrder.DESC).ToString()
            });

            _sortTypeSelect.AppendChild(new HTMLOptionElement()
            {
                TextContent = "Gekommen",
                Value = ((int)Enums.FaultListSortType.Comes).ToString()
            });
            _sortTypeSelect.AppendChild(new HTMLOptionElement()
            {
                TextContent = "Gegangen",
                Value = ((int)Enums.FaultListSortType.Goes).ToString()
            });

            _sortTypeSelect.AppendChild(new HTMLOptionElement()
            {
                TextContent = "Text",
                Value = ((int)Enums.FaultListSortType.Text).ToString()
            });

            _sortTypeSelect.AppendChild(new HTMLOptionElement()
            {
                TextContent = "Quittiert",
                Value = ((int)Enums.FaultListSortType.Quitted).ToString()
            });

            


            _sortTypeSelect.OnChange = SortChanged;
            _sortOrderSelect.OnChange = SortChanged;


            _innerBoxHeader = new HTMLDivElement()
            {
                Id = "faultlist_inner_header"
            };
            HTMLParagraphElement paragraph1 = new HTMLParagraphElement()
            {
                TextContent = "Sortieren nach: "
            };
            HTMLParagraphElement paragraph2 = new HTMLParagraphElement()
            {
                TextContent = " Reihenfolge"
            };
            paragraph1.AppendChild(_sortTypeSelect);
            paragraph2.AppendChild(_sortOrderSelect);
            _innerBoxHeader.AppendChild(paragraph1);
            _innerBoxHeader.AppendChild(paragraph2);
            ViewDivElement.AppendChild(_innerBoxHeader);



            _innerBoxList = new HTMLDivElement()
            {
                Id = "faultlist_inner_list"
            };

            HTMLDivElement overflowdiv = new HTMLDivElement()
            {
                ClassName = "fieldset_overflow_div"
            };
            overflowdiv.AppendChild(_innerBoxList);
            HTMLFieldSetElement fieldset = new HTMLFieldSetElement()
            {
                Id = "faultlist_box_fieldset"
            };
            fieldset.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Akutelle Störungen"
            });
            fieldset.AppendChild(overflowdiv);

            ViewDivElement.AppendChild(fieldset);
            

        }

        private void UpdateAll()
        {
            UpdateViewList();
        }

        private void Updated(FaultList.FaultListUpdate faultListUpdate)
        {
            UpdateViewList();
        }

        private void SortChanged(Event<HTMLSelectElement> @event)
        {
            if (_faultList == null)
            {
                return;
            }
            Settings.FaultSortType = (Enums.FaultListSortType) int.Parse(_sortTypeSelect.Value);
            Settings.FaultSortOrder = (Enums.SortOrder)int.Parse(_sortOrderSelect.Value);
            UpdateViewList();
        }

        

        private void UpdateViewList()
        {
            
            foreach (var winIt in _windowItems)
            {
                winIt.Remove();
            }
            _windowItems.Clear();

            SessionUserFault[] faults = _faultList.GetSortedList();

            foreach (var fault in faults)
            {
                FaultListWindowBoxViewItem viewItem = new FaultListWindowBoxViewItem(fault , _allowQuit);
                _innerBoxList.AppendChild(viewItem.GetViewElement());
                viewItem.Quitted += ElementQuitted;
                _windowItems.Add(viewItem);
            }


        }

        private void ElementQuitted(FaultElementQuitted faultElementQuitted)
        {
            _faultList.QuitFault(faultElementQuitted.ElementId);
        }


    }
}
