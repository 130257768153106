using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class SystemTreeWindow
    {
        public class TreeElementClicked
        {
            public int Id { set; get; }

            public TreeElementClicked(int id)
            {
                Id = id;
            }
        }

        public ToolWindow DraggableWindow
        {
            get { return _draggableWindow; }
        }


        private ToolWindow _draggableWindow;

        private IServerConnection _serverConnection;
        private MainWindow _mainWindow;

        private HTMLDivElement _systemTreePane;
        private HTMLDivElement _systemTreeSearch;
        private HTMLInputElement _systemTreeSearchBox;

        public SystemTreeWindow(MainWindow mainWindow, IServerConnection serverConnection)
        {
            _mainWindow = mainWindow;
            _serverConnection = serverConnection;


            _draggableWindow = new ToolWindow(mainWindow, "Anlagenbaum", "systemtree", 350, 600);
            _draggableWindow.MakeHidable();
            _draggableWindow.MakeResizable();
            _draggableWindow.MakeDraggable();
            _draggableWindow.SetMinimumSize(150,150);

            _systemTreeSearch = new HTMLDivElement()
            {
                Id = "system_tree_search"
            };
            var searchBoxWrapper = new HTMLDivElement()
            {
                Id = "system_tree_search_box_wrapper"
            };
            _systemTreeSearchBox = new HTMLInputElement()
            {
                Id = "system_tree_search_box"
            };
            _systemTreeSearchBox.OnChange = (ev) => DoSearch();
            searchBoxWrapper.AppendChild(_systemTreeSearchBox);
            _systemTreeSearch.AppendChild(new Text("Suche:"));
            _systemTreeSearch.AppendChild(searchBoxWrapper);

            _systemTreePane = new HTMLDivElement()
            {
                Id = "system_tree_pane"
            };

            _draggableWindow.GetContendArea().AppendChild(_systemTreeSearch);
            _draggableWindow.GetContendArea().AppendChild(_systemTreePane);
            
            SetHeight();
            
        }

        private void DoSearch()
        {
            var searchText = _systemTreeSearchBox.Value;

            foreach(var child in _systemTreePane.Children)
            {
HTMLUListElement ulChild;                if((ulChild = child as HTMLUListElement) != null)
                {
                   foreach(HTMLLIElement liChild in ulChild.Children)
                    {
                        FoundChildRecurseHelper(searchText, liChild);
                    }
                }
            }

        }

        private bool FoundChildRecurseHelper(string searchText, HTMLLIElement listElement)
        {            
            bool showChilds = false;
            bool showMe = false;

            foreach(var child in listElement.Children)
            {
HTMLAnchorElement anchorChild;                //will be anchor -> also contains searchtext
                if((anchorChild = child as HTMLAnchorElement) != null)
                {
                    //this element contains the text
                    if(anchorChild.TextContent.IndexOf( searchText, StringComparison.CurrentCultureIgnoreCase) >= 0)
                    {
                        showChilds = true;
                        showMe = true;
                    }
                }
                else {
    HTMLUListElement sublistChild;
    if ((sublistChild = child as HTMLUListElement) != null)
    {
        foreach (HTMLLIElement subList in sublistChild.Children)
        {
            //if mother is visible do without text
            var state = FoundChildRecurseHelper((showChilds ? "" : searchText), subList);
            if (state)
                showMe = true;
        }
    }
}
            }

            if (showMe)
            {
                //is visible
                listElement.Style.Display = Display.Block; //?

            }
            else
            {

                //is visible
                listElement.Style.Display = Display.None; //?
            }
            return showMe;

        }

        private void SetHeight()
        {
            
            this._draggableWindow.SetBounds(this._mainWindow.ContendArea.ClientHeight - 10,  (_mainWindow.ContendArea.ClientWidth > 1200 ? 350 : 250),0,0);


        }

        public bool IsVisible()
        {
            return (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.Display.As<Display>() != Display.None;
        }

        public void HideWindow()
        {
            (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.SetProperty("display", "none");
        }

        public void ShowWindow()
        {
            (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.SetProperty("display", "block");
            SetHeight();
            _serverConnection.SessionSystemTreeGetForUser((Action<SystemTreeItem[]>)UpdateTree, (Action<string>)(err => {  }));
            
        }



        private  void UpdateTree(IEnumerable<SystemTreeItem> treeItems)
        {
            foreach (var child in _systemTreePane.Children)
            {
                _systemTreePane.RemoveChild(child);
                //child.Remove();
            }


            HTMLUListElement treeList = new HTMLUListElement()
            {
                ClassName = "system_tree_list"
            };
            foreach (var systemTree in treeItems)
            {
                AddTreeSubElement(systemTree, treeList);
            }
            _systemTreePane.AppendChild(treeList);
            
        }

        private void AddTreeSubElement(SystemTreeItem treeItem, HTMLUListElement motherlistElement)
        {

            //open window
            if (treeItem.Type == SessionSystemTreeTypes.Link)
            {
                HTMLLIElement listElement = new HTMLLIElement()
                {
                    ClassName = "system_tree_clickable"
                };


                HTMLAnchorElement elementLink = new HTMLAnchorElement()
                {
                    ClassName = "system_tree_open_picture",
                    TextContent = treeItem.Text,
                    OnClick = @event =>
                    {
                        _mainWindow.OpenAnlagenbild(treeItem.Id);
                    }
                };
                listElement.AppendChild(elementLink);
                motherlistElement.AppendChild(listElement);

            }
            else if(treeItem.Type == SessionSystemTreeTypes.Folder)
            {
                HTMLLIElement listElement = new HTMLLIElement()
                {
                    ClassName = "system_tree_expandable"
                };

                HTMLAnchorElement elementLink = new HTMLAnchorElement()
                {
                    ClassName = "system_tree_expandable_link",
                    TextContent = treeItem.Text,

                };
                listElement.AppendChild(elementLink);

                //create sub list
                HTMLUListElement subList = new HTMLUListElement();

                elementLink.OnClick = @event =>
                {
                    if (subList.Style.Display.As<Display>() == Display.Block)
                    {
                        subList.Style.SetProperty("display", "none");
                    }
                    else
                    {
                        subList.Style.SetProperty("display", "block");
                    }
                };
                if (treeItem.SubItems != null )
                {
                    foreach (var systemTreeChild in treeItem.SubItems)
                    {
                        AddTreeSubElement(systemTreeChild, subList);
                    }
                }
                listElement.AppendChild(subList);
                motherlistElement.AppendChild(listElement);

            }

        }





    }
}
