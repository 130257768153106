using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;
using System.Linq;

namespace VisuPlusWebApp.WebView.Controls
{
    public class FaultListWindowListView : IFaultListWindowView
    {

        public HTMLDivElement ViewDivElement { private set; get; }
    
        private MovableTable _dataTable;
        private FaultList _faultList;
        private HTMLButtonElement _quitButton;
        private HTMLButtonElement _deleteButton;
        private HTMLInputElement _showInfoTextCheckBox;
        private MovableTable.MovableTableColumn _infotextCol;


        private class FaultTableRow : MovableTable.MovableTableRow
        {
            public SessionUserFault FaultItem { private set; get; }

            public FaultTableRow(SessionUserFault faultItem)
            {
                this.FaultItem = faultItem;
                this.ForeColor = FaultItem.Color;
                this.Cells.Add(new MovableTable.MovableTableCell(Functions.DatetimeToLocalDateTimeString(FaultItem.ListItem.Timecomes)));
                this.Cells.Add(new MovableTable.MovableTableCell(FaultItem.Text));
                this.Cells.Add(new MovableTable.MovableTableCell((FaultItem.ListItem.Timegoes != null) ?  Functions.DatetimeToLocalDateTimeString(FaultItem.ListItem.Timegoes) : ""));
                this.Cells.Add(new MovableTable.MovableTableCell(FaultItem.ListItem.Quitted ? "Q" : ""));
                this.Cells.Add(new MovableTable.MovableTableCell(FaultItem.InfoText));

            }


            public void Update(SessionUserFault updateFaultItem)
            { 
                this.FaultItem = updateFaultItem;
                this.ForeColor = FaultItem.Color;
                this.Cells[0].Text = Functions.DatetimeToLocalDateTimeString(FaultItem.ListItem.Timecomes);
                this.Cells[0].HtmlCell.TextContent = this.Cells[0].Text;
                this.Cells[1].Text = FaultItem.Text;
                this.Cells[1].HtmlCell.TextContent = this.Cells[1].Text;
                this.Cells[2].Text = (FaultItem.ListItem.Timegoes != null) ? Functions.DatetimeToLocalDateTimeString(FaultItem.ListItem.Timegoes) : "";
                this.Cells[2].HtmlCell.TextContent = this.Cells[2].Text;
                this.Cells[3].Text = FaultItem.ListItem.Quitted ? "Q" : "";
                this.Cells[3].HtmlCell.TextContent = this.Cells[3].Text;
                this.Cells[4].Text = FaultItem.InfoText;
            }
        }
        
        //private Dictionary<string, FaultTableRow> _rows = new Dictionary<string, FaultTableRow>(); 


        public void Init(FaultList faultList)
        {
            this._faultList = faultList;

            _faultList.Updated += Updated;
            _faultList.UpdateAll += UpdateAll;
            UpdateListView();
        }

        private void UpdateAll()
        {
            UpdateListView();
        }

        private void Updated(FaultList.FaultListUpdate faultListUpdate)
        {
            UpdateListView();
        }

        public void SetQuitMode(bool allow)
        {
            if (allow)
            {
                new jQuery(_quitButton).Show();
            }
            else
            {
                new jQuery(_quitButton).Hide();
            }
        }
        public void SetDeleteMode(bool allow)
        {
            if (allow)
            {
                new jQuery(_deleteButton).Show();
            }
            else
            {
                new jQuery(_deleteButton).Hide();
            }
        }

        public void Remove()
        {
            _faultList.Updated -= Updated;
            _faultList.UpdateAll -= UpdateAll;
            _dataTable.Dispose();
            //ViewDivElement.Remove();
            ViewDivElement.ParentElement.RemoveChild(ViewDivElement);

        }

        public FaultListWindowListView()
        {
            ViewDivElement = new HTMLDivElement()
            {
                Id = "faultlistviewlistview"
            };
            _dataTable = new MovableTable("faultlistviewtable", "mvtbl_table");
            _dataTable.AllowResizeColumns();
            _dataTable.AllowSortColumns();
            _dataTable.AllowSelectColumns();
            HTMLDivElement overflowdiv = new HTMLDivElement()
            {
                ClassName = "fieldset_overflow_div"
            };
            overflowdiv.AppendChild(_dataTable.HTMLElement);
            HTMLFieldSetElement fieldset = new HTMLFieldSetElement()
            {
                Id = "faultlist_lv_fieldset"
            };
            fieldset.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Aktuelle Störungen"
            });
            fieldset.AppendChild(overflowdiv);
            ViewDivElement.AppendChild(fieldset);
            
            _dataTable.AddColumn(new MovableTable.MovableTableColumn("Gekommen")
            {
                Width = 150,
                ColumnType = MovableTable.MovableTableColumn.ColumnTypes.Date
            });
            _dataTable.AddColumn(new MovableTable.MovableTableColumn("Störtext")
            {
                Width = 450,
                ColumnType = MovableTable.MovableTableColumn.ColumnTypes.Text
            });
            _dataTable.AddColumn(new MovableTable.MovableTableColumn("Gegangen")
            {
                Width = 150,
                ColumnType = MovableTable.MovableTableColumn.ColumnTypes.Date
            });
            _dataTable.AddColumn(new MovableTable.MovableTableColumn("Quittiert")
            {
                Width = 70,
                ColumnType = MovableTable.MovableTableColumn.ColumnTypes.Text
            });
            _infotextCol =new MovableTable.MovableTableColumn("Infotext")
            {
                Width = 600,
                ColumnType = MovableTable.MovableTableColumn.ColumnTypes.Text
            };
            _infotextCol.IsVisible = false;
            _dataTable.AddColumn(_infotextCol);
            _quitButton = new HTMLButtonElement()
            {
                Id = "faultlistview_quitbutton",
                TextContent = "Quittieren",
                OnClick = @event =>
                {
                    QuitFault();
                }
            };
            _deleteButton = new HTMLButtonElement()
            {
                Id = "faultlistview_deletebutton",
                TextContent = "Löschen",
                OnClick = @event =>
                {
                    DeleteFault();
                },
                Style =
                {
                    Display= Display.None
                }
            };
            _showInfoTextCheckBox = new HTMLInputElement()
            {
                Type = InputType.Checkbox,
                OnChange = @event =>
                {
                    _infotextCol.IsVisible = _showInfoTextCheckBox.Checked;
                    _dataTable.UpdateAll();
                }
            };
            ViewDivElement.AppendChild(_quitButton);
            ViewDivElement.AppendChild(_deleteButton);

            HTMLParagraphElement para = new HTMLParagraphElement()
            {
                Id = "faultlistview_showinfotexts",
            };
            para.AppendChild(_showInfoTextCheckBox);
            para.InsertAdjacentHTML(InsertPosition.BeforeEnd, "Zeige Infotexte");
            

            ViewDivElement.AppendChild(para);

            //string contextMenu = "<menu type=\"context\" id=\"flCntMenu\"><menuitem label=\"Quittieren\"><menuitem label=\"Infotext\"></menu>";
            //ViewDivElement.InsertAdjacentHTML(InsertPosition.BeforeEnd, contextMenu);
        }

        private void DeleteFault()
        {
                foreach (FaultTableRow movableTableRow in _dataTable.SelectedRows)
                {
                    Task.Run((Action)(() =>
                    {
                        _faultList.DeleteFault(movableTableRow.FaultItem.ListItem.Id);
                    }));
                }

            }
        

        private void QuitFault()
        {
            foreach (FaultTableRow movableTableRow in _dataTable.SelectedRows)
            {
                Task.Run((Action)(() =>
                {
                    _faultList.QuitFault(movableTableRow.FaultItem.ListItem.Id);
                }));
            }
        }


        private void UpdateListView()
        {
            _dataTable.BeginUpdate();
            var allFaults = _faultList.GetSortedList();
            
            foreach (var fault in allFaults)
            {
                FaultTableRow foundTableRow = null;
                
                foreach (var row in _dataTable.Rows)
                {
                    if ((row as FaultTableRow).FaultItem.ListItem.Id.ToString().Equals(fault.ListItem.Id.ToString()))
                    {
                        foundTableRow = row as FaultTableRow;
                        break;
                    }
                }
                //old
                if (foundTableRow != null)
                {
                    foundTableRow.Update(fault);
                }
                //new
                else
                {
                    var newItem = new FaultTableRow(fault);
                    newItem.DoubleClicked += () => {
                        Window.Alert("Infotext: " + fault.InfoText);
                    };
                    _dataTable.AddRow(newItem);
                }
            }

            var rowsToRemove = System.Linq.Enumerable.Where<MovableTable.MovableTableRow>(_dataTable.Rows,(System.Func<VisuPlusWebApp.WebView.Controls.MovableTable.MovableTableRow,bool>)(ele => !System.Linq.Enumerable.Any<SessionUserFault>(allFaults,(Func<SessionUserFault,bool>)(allFaultEle => allFaultEle.ListItem.Id.ToString().Equals((ele as FaultTableRow).FaultItem.ListItem.Id.ToString())))));
            
            _dataTable.RemoveRows(rowsToRemove);
            _dataTable.EndUpdate();
        }


        private void ElementQuitted(FaultElementQuitted faultElementQuitted)
        {
            _faultList.QuitFault(faultElementQuitted.ElementId);
        }


    }
}
