using System;
using System.Collections.Generic;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class FaultListWindow
    {
        private ToolWindow _draggableWindow;
        private MainWindow _mainWindow;

        private bool _islistview = true;
        private IFaultListWindowView _viewItem;

        private FaultList _faultList;

        private HTMLDivElement _listdiv;
        private HTMLDivElement _buttonDiv;
        private HTMLButtonElement _historyButton;
        private HTMLButtonElement _viewButton;

        private bool _allowQuit;
        private bool _allowDelete;

        public FaultListWindow(MainWindow mainWindow, FaultList faultList)
        {
            this._faultList = faultList;
            this._mainWindow = mainWindow;

            this._draggableWindow = new ToolWindow(mainWindow, "Störungen", "faultlist", 900, 600);
            _draggableWindow.MakeHidable();
            _draggableWindow.MakeResizable();
            _draggableWindow.MakeDraggable();
            _draggableWindow.SetMinimumSize(400, 200);

            Functions.CenterWindow(this._draggableWindow.GetHTMLObject());

            HideWindow();
            _faultList.Updated += Updated;
            _faultList.UpdateAll += UpdateAll;



            _listdiv = new HTMLDivElement()
            {
                Id = "fault_list_contend_div"
            };
            _buttonDiv = new HTMLDivElement()
            {
                Id = "faultlistbuttonarea"
            };

            _historyButton = new HTMLButtonElement()
            {
                OnClick = @event =>
                {
                    mainWindow.OpenFaultHistory();
                },
                TextContent = "Historie"
            };
            _viewButton = new HTMLButtonElement()
            {
                OnClick = @event =>
                {
                    _islistview = !_islistview;
                    SwitchView();

                },
                TextContent = "Ansicht"
            };

            _buttonDiv.AppendChild(_viewButton);
            _buttonDiv.AppendChild(_historyButton);
            _draggableWindow.GetContendArea().AppendChild(_listdiv);
            _draggableWindow.GetContendArea().AppendChild(_buttonDiv);

            SwitchView();
        }

        private void UpdateAll()
        {
            if (_faultList.HasFaults())
            {
                ShowWindow();
                Functions.CenterWindow(this._draggableWindow.GetHTMLObject());
            }
        }

        private void Updated(FaultList.FaultListUpdate faultListUpdate)
        {
            if (faultListUpdate.State == FaultTypes.Comes)
            {
                ShowWindow();
                Functions.CenterWindow(this._draggableWindow.GetHTMLObject());
            }
        }

        public void SetQuitMode(bool allow)
        {
            _allowQuit = allow;
            _viewItem.SetQuitMode(_allowQuit);
        }

        public void SetDeleteMode(bool allow)
        {
            _allowDelete = allow;
            _viewItem.SetDeleteMode(_allowDelete);
        }

        public void SetHistoryButton(bool state)
        {
            if (state)
            {
                new Bridge.jQuery2.jQuery(_historyButton).Show();
            }
            else
            {
                new Bridge.jQuery2.jQuery(_historyButton).Hide();
            }
                
        }
        private void FaultListUpdated()
        {
        }


        public void CenterWindow()
        {
            Functions.CenterWindow(this._draggableWindow.GetHTMLObject());

        }

        public void HideWindow()
        {
            (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.SetProperty("display", "none");
        }

        public void ShowWindow()
        {
            (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.SetProperty("display", "block");
            this._mainWindow.BringWindowToFront(this._draggableWindow);
        }

        public bool IsVisible()
        {
            return (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.Display.As<Display>() != Display.None;
        }

        private void SwitchView()
        {
            if (_viewItem != null)
            {
                _viewItem.Remove();
            }
            if (_islistview)
            {
                _viewItem = new FaultListWindowListView();
            }
            else
            {
                _viewItem = new FaultListWindowBoxView();
            }
            _viewItem.Init(_faultList);
            _listdiv.AppendChild(_viewItem.ViewDivElement);
            _viewItem.SetQuitMode(_allowQuit);

        }
    }
}
