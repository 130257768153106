using System;
using System.Collections.Generic;
using System.Linq;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class LoginWindow
    {
        public string UserName
        {
            get
            {          
                if (_showUserList)
                {
                    return (_userSelection.ChildNodes[_userSelection.SelectedIndex] as HTMLOptionElement).Value;
                }
                else
                {
                    return _userName.Value;
                }
            }

        }

        public string Password
        {
            get
            {
                return _passwordInput.Value;
            }

        }

        public bool StayLoggedIn
        {
            get { return _stayLoggedIn.Checked; }
        }
        

        private ToolWindow _draggableWindow;
        
        private HTMLSelectElement _userSelection;
        private HTMLInputElement _userName;
        private HTMLInputElement _passwordInput;
        private HTMLButtonElement _loginButton;
        private HTMLInputElement _stayLoggedIn;

        private MainWindow _mainWindow;

        //public System.Action<Event> LoggedIn;

        public Action LoggedIn;
        private IServerConnection _serverConnection;

        private bool _showUserList;

        public LoginWindow(MainWindow mainWindow, bool showUserList, IServerConnection serverConnection)
        {
            this._serverConnection = serverConnection;
            this._showUserList = showUserList;
            this._mainWindow = mainWindow;
            this._draggableWindow = new ToolWindow(mainWindow, "Login", "login", 400, 140);
            _draggableWindow.MakeHidable();
            _draggableWindow.MakeDraggable();

            _userSelection = new HTMLSelectElement();
            _userName = new HTMLInputElement();
            _passwordInput = new HTMLInputElement()
            {
                OnKeyPress = @event =>
                {
                    if (@event.As<KeyboardEvent>().KeyCode == 13)
                    {
                        LoggedIn();
                    }
                },
                Type = InputType.Password,
            };

            _loginButton = new HTMLButtonElement()
            {
                OnClick = @event =>
                {
                    LoggedIn();
                },
                TextContent = "Anmelden"
            };

            _stayLoggedIn = new HTMLInputElement()
            {
                Type = InputType.Checkbox
            };
            
            

            HTMLTableElement tbl = new HTMLTableElement();
            tbl.InsertRow(0);
            tbl.InsertRow(1);
            tbl.InsertRow(2);
            tbl.InsertRow(3);
            tbl.Rows[0].InsertCell(0).TextContent = "Benutzer:";
            if(showUserList) tbl.Rows[0].InsertCell(1).AppendChild( _userSelection );
            else tbl.Rows[0].InsertCell(1).AppendChild( _userName);
            tbl.Rows[1].InsertCell(0).TextContent = "Passwort:";
            tbl.Rows[1].InsertCell(1).AppendChild(_passwordInput);


            tbl.Rows[2].InsertCell(0).TextContent = "Angemeldet bleiben:";
            tbl.Rows[2].Cells[0].ColSpan = 2;
            tbl.Rows[2].Cells[0].AppendChild(_stayLoggedIn);
            tbl.Rows[3].InsertCell(0).TextContent = "";
            tbl.Rows[3].InsertCell(1).AppendChild(_loginButton);
            
            _draggableWindow.GetContendArea().AppendChild(tbl);
        }
        
        public void CenterWindow()
        {
            Functions.CenterWindow(this._draggableWindow.GetHTMLObject());

        }

        public void HideWindow()
        {
            (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.SetProperty("display", "none");
        }

        public void ShowWindow()
        {
            (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.SetProperty("display", "block");
            this._mainWindow.BringWindowToFront(this._draggableWindow);

        }

        public bool IsVisible()
        {
            return (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.Display.As<Display>() != Display.None;
        }
        
        public void LoadUserList()
        {
            _passwordInput.Value = "";
            _stayLoggedIn.Checked = false;            
            new Bridge.jQuery2.jQuery(_userSelection).Empty();
            _userName.Value = "";

            if (_showUserList)
            {
                _serverConnection.UserGetForLogin((Action<User[]>)((users) =>
                {

                    foreach (var userInfo in users)
                    {
                        _userSelection.AppendChild(new HTMLOptionElement()
                        {
                            TextContent = userInfo.FullName,
                            Value = userInfo.Name
                        });
                    }
                }), (Action<string>)(error => { }));
            }
        }

        

    }
}
