using System;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class FaultElementQuitted
    {
        public FaultId ElementId { private set; get; }

        public FaultElementQuitted(FaultId id)
        {
            ElementId = id;
        }
        
    }

    public class FaultListWindowBoxViewItem
    {
        public System.Action<FaultElementQuitted> Quitted;

        private SessionUserFault _fault;

        private HTMLFieldSetElement _viewFieldset;

        public FaultListWindowBoxViewItem(SessionUserFault fault, bool quittable)
        {
            this._fault = fault;
            _viewFieldset = new HTMLFieldSetElement()
            {
                ClassName = "faultlist_element",
                Style =
                {
                    Border = "5px solid " + fault.Color
                }
            };

            HTMLLegendElement faultText = new HTMLLegendElement()
            {
                ClassName = "faultlist_element_legend",
                TextContent = fault.Text
            };
            _viewFieldset.AppendChild(faultText);

            HTMLParagraphElement comesElement = new HTMLParagraphElement()
            {
                ClassName = "faultlist_element_comes",
                TextContent = "Gekommen: " + Functions.DatetimeToLocalDateTimeString(fault.ListItem.Timecomes)
            };
            _viewFieldset.AppendChild(comesElement);

            if (fault.ListItem.Timegoes != null)
            {
                HTMLParagraphElement goesElement = new HTMLParagraphElement()
                {
                    ClassName = "faultlist_element_goes",
                    TextContent = "Gegeangen: " + Functions.DatetimeToLocalDateTimeString( fault.ListItem.Timegoes)
                };
                _viewFieldset.AppendChild(goesElement);
            }
            if (fault.ListItem.Quitted)
            {
                HTMLSpanElement quittedElement = new HTMLSpanElement()
                {
                    ClassName = "faultlist_element_quitted",
                    TextContent = "Quittiert"
                };
                _viewFieldset.AppendChild(quittedElement);
            }
            else
            {
                if (quittable)
                {
                    HTMLAnchorElement quitElement = new HTMLAnchorElement()
                    {
                        ClassName = "faultlist_element_quit",
                        TextContent = "Quittieren",
                        OnClick = @event =>
                        {
                            Quit();
                        }
                    };
                    _viewFieldset.AppendChild(quitElement);

                }
                else
                {
                    HTMLSpanElement quittedElement = new HTMLSpanElement()
                    {
                        ClassName = "faultlist_element_quitted",
                        TextContent = "Nicht Quittiert"
                    };
                    _viewFieldset.AppendChild(quittedElement);
                }
            }

            HTMLParagraphElement infotextText = new HTMLParagraphElement()
            {
                ClassName = "faultlist_element_infotext_fieldset faultlist_element_infotext_collapsed",
                TextContent = fault.InfoText,


            };

            HTMLAnchorElement infotextShowLink = new HTMLAnchorElement()
            {
                ClassName = "faultlist_element_infotext_legend",
                TextContent = "Infotext",                
                OnClick = @event =>
                {
                    if (infotextText.ClassList.Contains("faultlist_element_infotext_collapsed"))
                    {
                        infotextText.ClassList.Remove("faultlist_element_infotext_collapsed");
                    }
                    else
                    {
                        infotextText.ClassList.Add("faultlist_element_infotext_collapsed");
                    }
                }
            };
            _viewFieldset.AppendChild(infotextShowLink);
            _viewFieldset.AppendChild(infotextText);

        }

        public void Quit()
        {
            if (Quitted != null)
            {
                Quitted(new FaultElementQuitted(_fault.ListItem.Id));
            }
        }

        public Node GetViewElement()
        {
            return _viewFieldset;
        }

        public void Remove()
        {
            Quitted = null;
            _viewFieldset.ParentElement.RemoveChild(_viewFieldset);
            //_viewFieldset.Remove();
        }


    }
}
