using System;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes.Types;

namespace VisuPlusWebApp.WebView.Controls
{
    public class AnlagenbildElementValueText : IAnlagenbildElement
    {
        public Action<AnlagenbildElementClicked> Clicked { set; get; }

        public int Id { set; get; }
        
        private Point _defaultPosition;
        private Size _defaultSize;
        private bool _editable;
        private string _backColor;
        private string _foreColor;
        private bool _isFixed;
        private string _text;
        private string _value;

        private HTMLDivElement _element;
        private HTMLParagraphElement _centerElement;

        public Node DisplayElement
        {
            get { return _element; }
        }

        public AnlagenbildElementValueText(ControlPictureStructureElement structureElement)
        {
            Id = structureElement.Id;
            _defaultPosition = new Point(structureElement.PosX, structureElement.PosY);
            _defaultSize = Size.FromWH(structureElement.Width, structureElement.Height);
            _editable = structureElement.Editable;
            _backColor = structureElement.BackColor;
            _foreColor = structureElement.ForeColor;
            _isFixed = structureElement.Fixedsize;
            _text = structureElement.Description;
            _value = structureElement.Data;

            CreateElement();
        }


        private void CreateElement()
        {
            _element = new HTMLDivElement()
            { 
                ClassName = "anlagenbild_window_element anlagenbild_window_element_text",
                OnDblClick = @event =>
                {
                    if (_editable)
                    {
                        Clicked(new AnlagenbildElementClicked(this.Id, AnlagenbildElementClicked.BildEventTypes.ChangeValue));
                    }
                },
                Title = _text,
                Style =
                {
                    BackgroundColor = _backColor,
                    Color = _foreColor,
                    Top = _defaultPosition.Y+ "px",
                    Left = _defaultPosition.X+ "px",
                    Width = _defaultSize.Width+ "px",
                    Height = _defaultSize.Height+ "px",
                    Display = _isFixed? Display.TableCell : Display.Inline
                },

            };

            _centerElement = new HTMLParagraphElement()
            {
                TextContent = _value,
                
            };
            _element.AppendChild(_centerElement);

            Resize(1, 1);
        }

        public void Resize(float scaleX, float scaleY)
        {
            int x = (int)(_defaultPosition.X * scaleX + 0.5);
            int y = (int)(_defaultPosition.Y * scaleY + 0.5);
            int w = (int)(_defaultSize.Width * scaleX + 0.5);
            int h = (int)(_defaultSize.Height * scaleY + 0.5);


            _element.Style.SetProperty("top", y + "px");
            _element.Style.SetProperty("left", x + "px");

            //if fixed set size
            if (_isFixed)
            {
                _element.Style.SetProperty("width", w + "px");
                _element.Style.SetProperty("height", h + "px");
            }
            _element.Style.SetProperty("font-size", ((double)(scaleY * AnlagenbildWindow.RegularFontSize)) + "px");

        }


        public void Update(ControlPictureUpdateVar var)
        {
            _centerElement.TextContent = var.Value;
            _element.Style.SetProperty("background-color", var.BackColor);
            _element.Style.SetProperty("color", var.ForeColor);
            if (var.Visible)
            {
                _element.Style.SetProperty("display", "table");
            }
            else
            {
                _element.Style.SetProperty("display", "none");
            }
        }
        public void SetZIndex(int index)
        {
            this._element.Style.SetProperty("z-index", index.ToString());
        }

    }
}
