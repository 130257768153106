using System;
using System.Collections.Generic;
using Bridge;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;
using VisuPlusWebApp.Classes.Types;


namespace VisuPlusWebApp.WebView.Controls
{
    public class AnlagenbildWindow
    {
        private ToolWindow _draggableWindow;
        private IServerConnection _serverConnection;

        private HTMLDivElement _anlagenbildContend;

        public string Id { set; get; }

        private MainWindow _mainWindow;

        private bool _stopUpdating = false;

        private Dictionary<int, IAnlagenbildElement> _elements = new Dictionary<int, IAnlagenbildElement>();

        private Size _defaultWindowSize;

        public System.Action Closed;

        public ToolWindow DraggableWindow
        {
            get { return _draggableWindow; }

        }

        public static string AnlagenbildIdName = "anlagenbild_";
        public static int RegularFontSize = 14;

        //is regular
        public AnlagenbildWindow(string id, MainWindow mainWindow, IServerConnection serverConnection)
        {
            this.Id = id;
            this._mainWindow = mainWindow;
            _serverConnection = serverConnection;
            _draggableWindow = new ToolWindow(mainWindow,"Anlagenbild Id:" + id, AnlagenbildIdName + id, 800,630);
            _draggableWindow.MakeCloseable();
            _draggableWindow.MakeResizable();
            _draggableWindow.MakeDraggable();
            
            _draggableWindow.Closed += GotClosed;
            _draggableWindow.SetMinimumSize(200,200);
            
            _draggableWindow.Resized += Resized;

            _anlagenbildContend = new HTMLDivElement()
            {
                ClassName = "anlagenbild_contend_area"
            };
            _draggableWindow.GetContendArea().AppendChild(_anlagenbildContend);
            _serverConnection.SystemPictureGetWindow(this.Id, (Action<ControlPictureStructure>)GenertateStructure, (Action<string>)((s) => { } ));
        }

        //is miniwindow
        public AnlagenbildWindow(string id, string windowId, int elementId, MainWindow mainWindow, IServerConnection serverConnection)
        {
            this.Id = id;
            this._mainWindow = mainWindow;
            _serverConnection = serverConnection;
            _draggableWindow = new ToolWindow(mainWindow, "Anlagenbild Id:" + id, "anlagenbild_" + id, 100, 100);
            _draggableWindow.MakeCloseable();
            _draggableWindow.MakeResizable();
            _draggableWindow.MakeDraggable();

            _draggableWindow.Closed += GotClosed;
            _draggableWindow.SetMinimumSize(50, 50);

            _draggableWindow.Resized += Resized;

            _anlagenbildContend = new HTMLDivElement()
            {
                ClassName = "anlagenbild_contend_area"
            };
            _draggableWindow.GetContendArea().AppendChild(_anlagenbildContend);
            _serverConnection.SystemPictureGetMiniWindow(windowId, elementId, (Action<ControlPictureStructure>)GenertateStructure, (Action<string>)(e => { }));
        }
        

        private void Resized()
        {
            HTMLDivElement contendArea = _draggableWindow.GetContendArea() as HTMLDivElement;

            float scaleX = (float)contendArea.ClientWidth / (float)_defaultWindowSize.Width;
            float scaleY = (float)contendArea.ClientHeight / (float)_defaultWindowSize.Height;
            

            foreach (var element in _elements.Values)
            {
                element.Resize(scaleX, scaleY);
            }
        }

        private void ServerError()
        {
            MessageBox.Show("Anlagenbild konnte nicht geöffnet werden", MessageBox.MessageBoxTypes.Error, 5000, _mainWindow.ContendArea);
            this._draggableWindow.CloseWindow();

        }

        private void GenertateStructure(ControlPictureStructure structure)
        {
            this._anlagenbildContend.Style.SetProperty("background-color" , structure.BackColor);
            this._defaultWindowSize = Size.FromWH(structure.Width, structure.Height);
            this._draggableWindow.Text = structure.Name;

            this.Id = structure.Id;
            
            this._draggableWindow.WindowSize = new Size()
            {
                Width = structure.Width,
                Height = structure.Height + 30
            };
            if (structure.HasBackImage)
            {
                _serverConnection.SystemPictureGetBackImage(Id, (Action<string>)(s =>
                {
                    _anlagenbildContend.Style.SetProperty("background-image", "url(\"" + s + "\")");
                }), (Action<string>)((s) => { }));
            }

            int zIndexCnt = 1;//structure.elements.Length + 1;
            foreach (var systemPictureStructureElement in structure.Elements)
            {

                IAnlagenbildElement element = null;
                switch (systemPictureStructureElement.Type)
                {
                    case ControlPictureElementTypes.Value:
                        element = new AnlagenbildElementValueNormal(systemPictureStructureElement);
                        break;
                    case ControlPictureElementTypes.Text:
                        element = new AnlagenbildElementValueText(systemPictureStructureElement);
                        break;
                    case ControlPictureElementTypes.Image:
                        element = new AnlagenbildElementValueImage(systemPictureStructureElement);
                        break;
                    case ControlPictureElementTypes.Windowbutton:
                        element = new AnlagenbildElementWindowButton(systemPictureStructureElement);
                        break;
                    case ControlPictureElementTypes.Miniwindowbutton:
                        element = new AnlagenbildElementMiniWindowButton(systemPictureStructureElement);
                        _serverConnection.SystemPictureGetVarImage(Id, element.Id, "sads", (Action<string>)(s =>
                        {
                            (element as AnlagenbildElementMiniWindowButton).SetImage(s);
                        } ), (Action<string>)(e =>
                        {
                            (element as AnlagenbildElementMiniWindowButton).SetImage("img/noPic.bmp");
                        }));
                        
                        break;
                    case ControlPictureElementTypes.Toggle:
                        element = new AnlagenbildElementToggleButton(systemPictureStructureElement);
                        break;
                    case ControlPictureElementTypes.Trigger:
                        element = new AnlagenbildElementTriggerButton(systemPictureStructureElement);
                        break;
                }
                if (element != null)
                {
                    _elements.Add(element.Id, element);
                   element.Clicked += ElementClicked;
                    element.SetZIndex(zIndexCnt++);
                    _anlagenbildContend.AppendChild(element.DisplayElement);
                }
            }

            //check for resize
            var oScaling = Window.LocalStorage["windowscaling"];
            int iScaling = 100;
            if (oScaling != null)
            {
                int.TryParse(oScaling.ToString(), out iScaling);
            }
            float factor = (float)iScaling/ (float)100;
            this.DraggableWindow.WindowSize = new Size()
            {
                Height =  (int)(_draggableWindow.WindowSize.Height * factor),
                Width = (int)(_draggableWindow.WindowSize.Width * factor)
            };
            this.Resized();

            UpdateDataRoutineWait(true);
        }

        private void ElementClicked(AnlagenbildElementClicked anlagenbildElementClicked)
        {
            if (anlagenbildElementClicked.BildEventType == AnlagenbildElementClicked.BildEventTypes.OpenWindow)
            {
                _mainWindow.OpenAnlagenbild(anlagenbildElementClicked.OpenWindowId);
            }
            else if (anlagenbildElementClicked.BildEventType == AnlagenbildElementClicked.BildEventTypes.OpenMiniWindow)
            {
int iId;
                if(int.TryParse(anlagenbildElementClicked.OpenWindowId, out iId)){

                    _mainWindow.OpenMiniWindow(this.Id, iId);
                }
            }
            else if(anlagenbildElementClicked.BildEventType == AnlagenbildElementClicked.BildEventTypes.ChangeValue)
            {
                new ChangeValueWindow(_mainWindow, _serverConnection, Id, anlagenbildElementClicked.ElementId);
            }
            else if (anlagenbildElementClicked.BildEventType == AnlagenbildElementClicked.BildEventTypes.Toggle)
            {
                _serverConnection.SystemPictureSetValue(Id, anlagenbildElementClicked.ElementId, "toggle", (Action<bool>)((b) => { }), (Action<string>)(e =>{}));
            }
            else if (anlagenbildElementClicked.BildEventType == AnlagenbildElementClicked.BildEventTypes.TriggerHold)
            {
                _serverConnection.SystemPictureSetValue(Id, anlagenbildElementClicked.ElementId, "triggerhold", (Action<bool>)((b) => { }), (Action<string>)(e => { }));
            }
            else if (anlagenbildElementClicked.BildEventType == AnlagenbildElementClicked.BildEventTypes.TriggerReleased)
            {
                _serverConnection.SystemPictureSetValue(Id, anlagenbildElementClicked.ElementId, "triggerrelease", (Action<bool>)((b) => { }), (Action<string>)(e => { }));
            }

        }

        public void CloseWindow()
        {
            this._draggableWindow.CloseWindow();
        }


        private void GotClosed()
        {
            _stopUpdating = true;
            if (this.Closed != null)
            {
                Closed();
            }
        }


        private void UpdateDataRoutine(ControlPictureUpdate varUpdate)
        {
            try
            {
                foreach (var updateVar in varUpdate.Vars)
                {
                    UpdateElement(updateVar);
                }
            }
            catch( Exception ex)
            {
               // Console.WriteLine("error while updating vars " + ex.ToString());
            }
            finally
            {
                if (!_stopUpdating)
                {
                    Window.SetTimeout((Action)(() =>
                    {
                        UpdateDataRoutineWait(false);
                    }), 1000);
                }
            }
        }

        private void UpdateDataRoutineWait(bool fullUpdate)
        {
            if (!_stopUpdating)
            {
                try
                {
                    this._serverConnection.SystemPictureGetVarUpdate(this.Id, fullUpdate, (Action<ControlPictureUpdate>)UpdateDataRoutine, (Action<string>)UpdateDataRoutineHasError);
                }
                catch
                {
                    UpdateDataRoutineHasError(null);
                }
            }
        }

        private void UpdateDataRoutineHasError(object o)
        {
            //Console.WriteLine("ohh error while getting update");
            MessageBox.Show("Fehler beim Updaten des Fensters: <br>" + this._draggableWindow.Text, MessageBox.MessageBoxTypes.Error, 5000, this._anlagenbildContend);
     
            if (!_stopUpdating)
            {
                Window.SetTimeout((Action)(() =>
                {
                    UpdateDataRoutineWait(true);
                }), 3000);
            }
        }



        private void UpdateElement(ControlPictureUpdateVar updateVar)
        {
            try
            {
                IAnlagenbildElement element = null;
                if (_elements.ContainsKey(updateVar.VarId))
                {
                    element = _elements[updateVar.VarId];
                }
                if (element != null)
                {
                        element.Update(updateVar);
                    //update image
                    if (element.GetType() == typeof (AnlagenbildElementValueImage))
                    {
                        var imgElement = element as AnlagenbildElementValueImage;
                        if (imgElement.NeedImageUpdate)
                        {
                            
                            _serverConnection.SystemPictureGetVarImage(Id, element.Id, element.As<AnlagenbildElementValueImage>().Value, (Action<string>)(s =>
                            {
                                imgElement.SetImage(s);
                            }), 
(Action<string>)(                            e => {
                                imgElement.SetImage("img/noPic.bmp");
                            }));
                        }
                    }
                }
            }
            catch
            {
                //Console.WriteLine("Error Updating Var Window: " + Id);
            }
        }



    }
}
