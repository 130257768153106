using System;
using System.Collections.Generic;
using Bridge;
using Bridge.Bootstrap3;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class FaultHistoryWindow
    {
        private ToolWindow _draggableWindow;
        private MainWindow _mainWindow;

        private HTMLInputElement _rangeStart;
        private HTMLInputElement _rangeEnd;
        private HTMLButtonElement _submitButton;

        private HTMLInputElement _searchText;
        private HTMLButtonElement _searchButton;

        private MovableTable _historyTable;



        private IServerConnection _serverConnection;
        

        public FaultHistoryWindow(MainWindow mainWindow, IServerConnection serverConnection)
        {
            _serverConnection = serverConnection;

            HTMLDivElement headDiv = new HTMLDivElement()
            {
                Id = "faulthistoryhead"
            };
            
            HTMLDivElement overscrollDiv = new HTMLDivElement()
            {
                Id = "fault_history_data_pane"
            };



            _rangeStart = new HTMLInputElement()
            {
                ClassName = "faulthistoryheaditem",
                Id = "faulthistoryrangestart"
            };
            _rangeEnd = new HTMLInputElement()
            {
                ClassName = "faulthistoryheaditem",
                Id = "faulthistoryrangeend"
            };
            _submitButton = new HTMLButtonElement()
            {
                ClassName = "faulthistoryheaditem",
                Id = "faulthistoryrangesubmit",
                TextContent = "Anzeigen",
                OnClick = @event =>
                {
                    GetHistory();
                }
            };

            headDiv.AppendChild(new HTMLSpanElement()
            {
                ClassName = "faulthistoryheaditem",
                TextContent = "Start:"
            });
            headDiv.AppendChild(_rangeStart);
            headDiv.AppendChild(new HTMLSpanElement()
            {
                ClassName = "faulthistoryheaditem",
                TextContent = "Ende:"
            });
            headDiv.AppendChild(_rangeEnd);
            headDiv.AppendChild(_submitButton);


            _searchText = new HTMLInputElement()
            {
                ClassName = "faulthistoryheaditem",
                Id = "faulthistorysearchtrextinput"
            };
            _searchButton = new HTMLButtonElement()
            {
                ClassName = "faulthistoryheaditem",
                Id = "faulthistoryrangesubmit",
                TextContent = "Suchen",
                OnClick = @event =>
                {
                    SearchHistory();
                }
            };
            headDiv.AppendChild(new HTMLSpanElement()
            {
                TextContent = "Suchen:",
                ClassName = "faulthistoryheaditem"
            });
            headDiv.AppendChild(_searchText);
            headDiv.AppendChild(_searchButton);


            _historyTable = new MovableTable("faulthistorytable", "mvtbl_table thead");
            _historyTable.AddColumn("Typ", 80);
            _historyTable.AddColumn("Datum",150);
            _historyTable.AddColumn("Störung",500);
            _historyTable.AddColumn("Benutzer",100);
            _historyTable.AllowResizeColumns();
            _historyTable.AllowSortColumns();

            overscrollDiv.AppendChild(_historyTable.HTMLElement);


            this._mainWindow = mainWindow;

            this._draggableWindow = new ToolWindow(mainWindow, "Störhistorie", "fauthistory", 850, 600);
            _draggableWindow.MakeResizable();
            _draggableWindow.MakeDraggable();
            _draggableWindow.SetMinimumSize(850,200);
            _draggableWindow.MakeCloseable();

            _draggableWindow.GetContendArea().AppendChild(headDiv);
            _draggableWindow.GetContendArea().AppendChild(overscrollDiv);
            _draggableWindow.GetContendArea().AppendChild(new HTMLSpanElement()
            {
                TextContent = "Es werden maximal 1000 Einträge angezeigt",
                Id = "faulthistoryinfotext"
            });

            Script.Call("SetDatetimePicker", "#faulthistoryrangestart", DateTime.Now.AddDays(-1));
             Script.Call("SetDatetimePicker", "#faulthistoryrangeend", DateTime.Now);


            _draggableWindow.Closed += Closed;
            //Script.Call("SetTimeToPicker", "#faulthistoryrangeend", "01.01.2016 13:37");

            _serverConnection.FaultHistoryGetAll((Action<FaultHistoryItem[]>)FillList, (Action<string>)(e => { }));
        }

        private void Closed()
        {
            _historyTable.Dispose();
            Script.Call("RemoveDatetimePicker", "#faulthistoryrangestart");
            Script.Call("RemoveDatetimePicker", "#faulthistoryrangeend");

        }


        private void GetHistory()
        {
            //Window.Alert(_rangeStart.Value);
            //find date
            DateTime jsStart = Script.Call<DateTime>("GetTimeFromPicker", "#faulthistoryrangestart");
            DateTime jsEnd = Script.Call<DateTime>("GetTimeFromPicker", "#faulthistoryrangeend");

            _serverConnection.FaultHistoryGetRange(jsStart, jsEnd, (Action<FaultHistoryItem[]>)FillList, (Action<string>)(e => { }));
            
        }

        private void SearchHistory()
        {
            string text = _searchText.Value;
            
            _serverConnection.FaultHistorySearch(text, (Action<FaultHistoryItem[]>)FillList, (Action<string>)(e => { }));
            
        }


        private void FillList(IEnumerable< FaultHistoryItem> items)
        {
            _historyTable.ClearRows();
            _historyTable.BeginUpdate();
            foreach (var faultHistory in items)
            {
                var row = new MovableTable.MovableTableRow();
                row.AddCell(faultHistory.Type.ToString());
                row.AddCell(Functions.DatetimeToLocalDateTimeString(faultHistory.Time));
                row.AddCell(faultHistory.Text);
                row.AddCell(faultHistory.Username);
                _historyTable.AddRow(row);
            }
            _historyTable.EndUpdate();
        }


    
    }
}
