using Bridge.Html5;

namespace VisuPlusWebApp.WebView.Controls
{
    public static class Overlay
    {

        private static HTMLDivElement _overlayDiv;

        public static void CreateInstance(MainWindow mainWindow)
        {
            _overlayDiv = new HTMLDivElement()
            {
                Id = "overlay"
            };
            (Document.GetElementById("main") as HTMLDivElement).AppendChild(_overlayDiv);
        }


        public static void Show()
        {
            _overlayDiv.Style.SetProperty("display", "block");
        }

        public static void Hide()
        {
            _overlayDiv.Style.SetProperty("display", "none");

        }

    }
}
