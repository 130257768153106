using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class TrendSaveOverlay 
    {
        private ToolWindow _toolWindow;
        private IServerConnection _serverConnection;
        

        private HTMLInputElement _seriesName;
        private HTMLInputElement _seriesDescription;
        private HTMLInputElement _seriesPrivate;



        private HTMLButtonElement _saveButton;
        private HTMLButtonElement _cancelButton;

        private bool _seriesIsFreeRange;
        private DateTime _seriesFreeRangeStart;
        private DateTime _seriesFreeRangeEnd;

        private TimeSpan _seriesFixedRangeSpan;

        private string _databaseName;

        private TrendWindow.TrendPlotDataPoint[] _selectedItems;

        private HTMLSpanElement _elementCounter;

        MainWindow _mainWindow;
        
        public TrendSaveOverlay(MainWindow mainWindow, IServerConnection serverConnection, bool isFreeSeries, DateTime start, DateTime end, TimeSpan span, string database, TrendWindow.TrendPlotDataPoint[] selectedItems) 
        {
            _selectedItems = selectedItems;
            _mainWindow = mainWindow;
            _seriesIsFreeRange = isFreeSeries;
            _seriesFreeRangeStart = start;
            _seriesFreeRangeEnd = end;
            _seriesFixedRangeSpan = span;
            _databaseName = database;
            _serverConnection = serverConnection;

            Overlay.Show();

            _toolWindow = new ToolWindow(mainWindow, "Trendauswahl speichern", "trend_saved_series_box_load", 480, 300);
            _toolWindow.MakeCloseable();
            _toolWindow.Closed += WindowClosed;
            Window.OnKeyDown += OnKeyDown;
            mainWindow.BringWindowToFront(_toolWindow);

            HTMLFieldSetElement fieldsetInfo = new HTMLFieldSetElement()
            {
                
            };
            fieldsetInfo.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Info"
            });

            HTMLTableElement infoTable = new HTMLTableElement()
            {
                Style = { Width = "100%" }
            };

            HTMLTableRowElement rowName = new HTMLTableRowElement();
            HTMLTableDataCellElement colNameText = new HTMLTableDataCellElement()
            {
                TextContent = "Name: "
            };
            HTMLTableDataCellElement colNameItem = new HTMLTableDataCellElement();
            _seriesName = new HTMLInputElement()
            {
                Style =
                {
                    Width = "100%"
                }
            };
            colNameItem.AppendChild(_seriesName);
            rowName.AppendChild(colNameText);
            rowName.AppendChild(colNameItem);
            infoTable.AppendChild(rowName);


            HTMLTableRowElement rowDescription = new HTMLTableRowElement();
            HTMLTableDataCellElement colDescriptionText = new HTMLTableDataCellElement()
            {
                TextContent = "Beschreibung: "
            };
            HTMLTableDataCellElement colDescriptionItem = new HTMLTableDataCellElement();
            _seriesDescription = new HTMLInputElement()
            {
                Style =
                {
                    Width = "100%"
                }
            };
            colDescriptionItem.AppendChild(_seriesDescription);
            rowDescription.AppendChild(colDescriptionText);
            rowDescription.AppendChild(colDescriptionItem);
            infoTable.AppendChild(rowDescription);

            
            HTMLTableRowElement rowPrivate = new HTMLTableRowElement();
            HTMLTableDataCellElement colPrivateText = new HTMLTableDataCellElement()
            {
                TextContent = "Privat: "
            };
            HTMLTableDataCellElement colPrivateItem = new HTMLTableDataCellElement();
            _seriesPrivate = new HTMLInputElement()
            {
                Type = InputType.Checkbox
            };
            colPrivateItem.AppendChild(_seriesPrivate);
            rowPrivate.AppendChild(colPrivateText);
            rowPrivate.AppendChild(colPrivateItem);
            infoTable.AppendChild(rowPrivate);


            HTMLTableRowElement rowDatabase = new HTMLTableRowElement();
            HTMLTableDataCellElement colDatabaseText = new HTMLTableDataCellElement()
            {
                TextContent = "Datenbank: "
            };
            HTMLTableDataCellElement colDatabaseItem = new HTMLTableDataCellElement()
            {
                TextContent =  string.IsNullOrWhiteSpace(_databaseName) ? "Standard" : _databaseName
            };
            
            rowDatabase.AppendChild(colDatabaseText);
            rowDatabase.AppendChild(colDatabaseItem);
            infoTable.AppendChild(rowDatabase);


            HTMLTableRowElement rowElements = new HTMLTableRowElement();
            HTMLTableDataCellElement colElementsText = new HTMLTableDataCellElement()
            {
                TextContent = "Elemente: "
            };
            HTMLTableDataCellElement colElementsItem = new HTMLTableDataCellElement();
            _elementCounter = new HTMLSpanElement()
            {
                TextContent = "0"
            };

            rowElements.AppendChild(colElementsText);
            rowElements.AppendChild(colElementsItem);
            infoTable.AppendChild(rowElements);

            
            fieldsetInfo.AppendChild(infoTable);


            HTMLFieldSetElement fieldsetInterval = new HTMLFieldSetElement();
            fieldsetInterval.AppendChild(new HTMLLegendElement()
            {
                TextContent = "Zeitspanne"
            });


            HTMLTableElement intervalTable = new HTMLTableElement();

            HTMLTableRowElement rowIntervalType = new HTMLTableRowElement();
            HTMLTableDataCellElement colIntervalTypeName = new HTMLTableDataCellElement()
            {
                TextContent = "Intervalltyp: "
            };
            HTMLTableDataCellElement colIntervalTypeValue = new HTMLTableDataCellElement();
            rowIntervalType.AppendChild(colIntervalTypeName);
            rowIntervalType.AppendChild(colIntervalTypeValue);
            intervalTable.AppendChild(rowIntervalType);


            HTMLTableRowElement rowIntervalText = new HTMLTableRowElement();
            HTMLTableDataCellElement colIntervalTextText = new HTMLTableDataCellElement()
            {
                TextContent = "Intervall: "
            };
            HTMLTableDataCellElement colIntervalTextValue = new HTMLTableDataCellElement();

            rowIntervalText.AppendChild(colIntervalTextText);
            rowIntervalText.AppendChild(colIntervalTextValue);
            intervalTable.AppendChild(rowIntervalText);

            
            if (isFreeSeries)
            {
                colIntervalTypeValue.TextContent = "Frei";
                colIntervalTextValue.InnerHTML =  "Start:" + Classes.Functions.DatetimeToLocalDateTimeString(start) + "<br>Ende: " + Functions.DatetimeToLocalDateTimeString(end);
            }
            else
            {
                colIntervalTypeValue.TextContent = "Fix";
                colIntervalTextValue.TextContent =  Functions.TimespanToString(span);
            }


            fieldsetInterval.AppendChild(intervalTable);

            HTMLButtonElement buttonOk = new HTMLButtonElement()
            {
                TextContent = "Speichern",
                OnClick = @event =>
                {
                    Save();
                }
            };

            HTMLButtonElement buttonCancel = new HTMLButtonElement()
            {
                TextContent = "Abbrechen",
                OnClick = @event =>
                {
                    _toolWindow.CloseWindow();
                }
            };

            HTMLDivElement buttonpane = new HTMLDivElement()
            {
                Id = "trend_select_database_buttons"
            };
            buttonpane.AppendChild(buttonOk);
            buttonpane.AppendChild(buttonCancel);



            _toolWindow.GetContendArea().AppendChild(fieldsetInfo);
            _toolWindow.GetContendArea().AppendChild(fieldsetInterval);
            _toolWindow.GetContendArea().AppendChild(buttonpane);

        }



        private void OnKeyDown(KeyboardEvent keyboardEvent)
        {
            if (keyboardEvent.KeyCode == 27)
            {
                CloseWindow();
            }
        }

        private bool Validate()
        {
            if (string.IsNullOrWhiteSpace(_seriesName.Value))
            {
                _seriesName.ClassList.Add("input_validate_error");
                return false;
            }
            else
            {
                _seriesName.ClassList.Remove("input_validate_error");
            }
            return true;
        }

        private bool Save()
        {
            if (Validate())
            {
                var series = new TrendUserSelection()
                {
                    Database = _databaseName,
                    Description = _seriesDescription.Value,
                    FixedRangeInterval = (int)_seriesFixedRangeSpan.TotalSeconds,
                    FreeRangeEnd = _seriesFreeRangeEnd,
                    FreeRangeStart = _seriesFreeRangeStart,
                    Id = -1,
                    IsFreeRange = _seriesIsFreeRange,
                    IsPublic = !_seriesPrivate.Checked,
                    Name = _seriesName.Value,
                    UserId = _mainWindow.LoggedInUserId,
                    Username = _mainWindow.LoggedInUserName
                };

                _serverConnection.TrendUserSelectionAdd(series, 
(System.Action<int>)(                id =>
                {
                    TrendUserSelectionItem[] parsedItems = new TrendUserSelectionItem[_selectedItems.Length];
                    for (int i = 0; i < _selectedItems.Length; i++)
                    {
                        parsedItems[i] = new TrendUserSelectionItem()
                        {
                            Color = _selectedItems[i].Color,
                             TrendIndexId = _selectedItems[i].IndexItem.Id,
                            Increase = _selectedItems[i].Increase
                        };
                    }
                    _serverConnection.TrendUserSelectionItemsAdd(id, parsedItems, (Action<bool>)(b =>
                    {
                        if(b)
                        {
                           
                        }
                        _toolWindow.CloseWindow();
                        MessageBox.Show("Gespeichert", MessageBox.MessageBoxTypes.Info, 2000, _mainWindow.MainWindowDiv);

                    }),
(Action<string>)(                    err =>
                    {                    
                        MessageBox.Show("Elemente konnten nicht hinzugefügt werden<br>" + err, MessageBox.MessageBoxTypes.Error, 2000, _mainWindow.MainWindowDiv);
                    }));

                }),
(Action<string>)(                err =>
                {
                    MessageBox.Show("Konnte nicht gespeichert werden<br>" + err, MessageBox.MessageBoxTypes.Error, 2000, _mainWindow.MainWindowDiv);
                    
                }));
                

            }
            return false;
        }

        
        
        private void WindowClosed()
        {
            Window.OnKeyDown -= OnKeyDown;
            Overlay.Hide();
        }


        private void CloseWindow()
        {
            _toolWindow.CloseWindow();
        }
    }
}
