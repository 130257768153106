using System;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes.Types;
using Point = VisuPlusWebApp.Classes.Types.Point;

namespace VisuPlusWebApp.WebView.Controls
{
    public class AnlagenbildElementMiniWindowButton : IAnlagenbildElement
    {
        public Action<AnlagenbildElementClicked> Clicked { set; get; }

        public int Id { set; get; }

        private Point _defaultPosition;
        private Size _defaultSize;
        private bool _editable;
        private string _backColor;
        private bool _isFixed;
        private string _text;
        private string _value;

        private HTMLButtonElement _element;

        public Node DisplayElement
        {
            get { return _element; }
        }

        public void Update(ControlPictureUpdateVar var)
        {
            //nothing
        }

        public AnlagenbildElementMiniWindowButton(ControlPictureStructureElement structureElement)
        {
            Id = structureElement.Id;
            _defaultPosition = new Point(structureElement.PosX, structureElement.PosY);
            _defaultSize = Size.FromWH(structureElement.Width, structureElement.Height);
            _editable = structureElement.Editable;
            _backColor = structureElement.BackColor;
            _isFixed = structureElement.Fixedsize;
            _text = structureElement.Description;
            _value = structureElement.Data;

            CreateElement();
        }


        private void CreateElement()
        {
            _element = new HTMLButtonElement()
            {
                ClassName = "anlagenbild_window_element anlagenbild_window_element_button",
                OnClick = @event =>
                {
                    if (_editable)
                    {
                        Clicked(new AnlagenbildElementClicked(this.Id,
                            AnlagenbildElementClicked.BildEventTypes.OpenMiniWindow)
                        {
                            OpenWindowId = this._value
                        });
                    }

                },
                Title = _text,
                Style =
                {
                    Color = _backColor,
                    Top = _defaultPosition.Y+ "px",
                    Left = _defaultPosition.X+ "px",
                    Width = _defaultSize.Width+ "px",
                    Height = _defaultSize.Height+ "px",
                    Display = _isFixed? Display.TableCell : Display.Inline,
                    BackgroundSize = "100% 100%"
                },
                TextContent = _text,

            };
         Resize(1,1);
    }

        public void SetImage(string path)
        {
            _element.Style.SetProperty("background-image", "url(\"" + path + "\")");
            //jQuery.Ajax(new AjaxOptions()
            //{
            //    Url = path,
            //    Cache = false,
            //    DataType = "text",
            //    Type = "GET",
            //    Success = (o, s, arg3) =>
            //    {
            //    }
            //});
        }
        

        public void Resize(float scaleX, float scaleY)
    {
        int x = (int)(_defaultPosition.X * scaleX + 0.5);
        int y = (int)(_defaultPosition.Y * scaleY + 0.5);
        int w = (int)(_defaultSize.Width * scaleX + 0.5);
        int h = (int)(_defaultSize.Height * scaleY + 0.5);


        _element.Style.SetProperty("top", y + "px");
        _element.Style.SetProperty("left", x + "px");

        //if fixed set size
        if (_isFixed)
        {
            _element.Style.SetProperty("width", w + "px");
            _element.Style.SetProperty("height", h + "px");
        }
        }
        public void SetZIndex(int index)
        {
            this._element.Style.SetProperty("z-index", index.ToString());
        }




    }
}
