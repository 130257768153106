using System;
using Bridge.Html5;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class ProjectImageWindow
    {
        private ToolWindow _draggableWindow;

        private MainWindow _mainWindow;
        private HTMLDivElement _projectImageDiv;
        private IServerConnection _serverConnection;


        public ProjectImageWindow(MainWindow mainWindow)
        {

            _mainWindow = mainWindow;
            _draggableWindow = new ToolWindow(mainWindow, "", "project_image", 500, 300);
            _draggableWindow.MakeHidable();
            _draggableWindow.MakeDraggable();
            
            _projectImageDiv = new HTMLDivElement()
            {
                Id = "project_image_image"
            };

            _draggableWindow.GetContendArea().AppendChild(_projectImageDiv);

            ShowAndCenter();
        }

        public void SetBackImageFile(object path)
        {
          //  Console.WriteLine("settings iamge");
            
            
           _projectImageDiv.Style.SetProperty("background-image","url(" + path +  ")");
           // Console.WriteLine(path.ToString().Substring(0,100));
           // Console.WriteLine("setted");
        }


        public void ShowAndCenter()
        {

            (_draggableWindow.GetHTMLObject() as HTMLDivElement).Style.SetProperty("display", "block");
            this._mainWindow.BringWindowToFront(this._draggableWindow);


            var htmlObject = (_draggableWindow.GetHTMLObject() as HTMLDivElement);
            int parentWidth = htmlObject.ParentElement.ClientWidth;
            int parentHeight = htmlObject.ParentElement.ClientHeight;

            htmlObject.Style.SetProperty("left", ((parentWidth/2) - (htmlObject.ClientWidth/2)) + "px");
            htmlObject.Style.SetProperty("top", ((parentHeight/2) - (htmlObject.ClientHeight/2)) + "px");
        }
    }

}
