using System;
using System.Threading.Tasks;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes.Types;

namespace VisuPlusWebApp.WebView.Controls
{
    public class AnlagenbildElementValueImage : IAnlagenbildElement
    {
        public Action<AnlagenbildElementClicked> Clicked { set; get; }
        public int Id { set; get; }

        public string Value
        {
            get { return _value; }
        }

        private Point _defaultPosition;
        private Size _defaultSize;
        private string _backColor;
        private bool _isFixed;
        private string _text;

        private string _value = "";

        private HTMLDivElement _element;

        private bool _imageUpdatePending = false;
        private bool _imageNeedsUpdate = false;

        public Node DisplayElement
        {
            get { return _element; }
        }

        public AnlagenbildElementValueImage(ControlPictureStructureElement structureElement)
        {
            Id = structureElement.Id;
            _defaultPosition = new Point(structureElement.PosX, structureElement.PosY);
            _defaultSize = Size.FromWH(structureElement.Width, structureElement.Height);
            _backColor = structureElement.BackColor;
            _isFixed = structureElement.Fixedsize;
            _text = structureElement.Description;

            if (structureElement.Editable)
            {
                _element.OnDblClick += @event =>
                {
                    Clicked(new AnlagenbildElementClicked(structureElement.Id,
                        AnlagenbildElementClicked.BildEventTypes.ChangeValue));
                };
            }

            CreateElement();
        }


        private void CreateElement()
        {
            _element = new HTMLDivElement()
            {
                ClassName = "anlagenbild_window_element anlagenbild_window_element_image",
                Title = _text,
                Style =
                {
                    Color = _backColor,
                    Top = _defaultPosition.Y+ "px",
                    Left = _defaultPosition.X+ "px",
                    Width = _defaultSize.Width+ "px",
                    Height = _defaultSize.Height+ "px",
                    Display = _isFixed? Display.Block : Display.Inline,
                    BackgroundSize = "100% 100%"
                },
            };
            Resize(1, 1);
        }

        public void Resize(float scaleX, float scaleY)
        {
            int x = (int)(_defaultPosition.X * scaleX + 0.5);
            int y = (int)(_defaultPosition.Y * scaleY + 0.5);
            int w = (int)(_defaultSize.Width * scaleX + 0.5);
            int h = (int)(_defaultSize.Height * scaleY + 0.5);


            _element.Style.SetProperty("top", y + "px");
            _element.Style.SetProperty("left", x + "px");

            //if fixed set size
            if (_isFixed)
            {
                _element.Style.SetProperty("width", w + "px");
                _element.Style.SetProperty("height", h + "px");
            }
        }


        public void Update(ControlPictureUpdateVar var)
        {
            //_element. = var.value;
            //_element.Style.SetProperty("background-color", var.backColor);
            //_element.Style.SetProperty("color", var.foreColor);
            if (var.Visible)
            {
                _element.Style.SetProperty("display", "inline");
                if (_value != var.Value)
                {
                    _value = var.Value;
                    _imageNeedsUpdate = true;
                }
            }
            else
            {
                _element.Style.SetProperty("display", "none");
            }
        }

        public bool NeedImageUpdate
        {
            get
            {
                if (_imageNeedsUpdate && !_imageUpdatePending)
                {
                    _imageUpdatePending = true;
                    _imageNeedsUpdate = false;
                    return true;
                }
                return false;
            }
        }

        public void SetImage(string path)
        {
            _imageUpdatePending = false;
            _element.Style.SetProperty("background-image", "url(\""+ path+"\")");
        }
        public void SetZIndex(int index)
        {
            this._element.Style.SetProperty("z-index", index.ToString());
        }

    }
}
