using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;


namespace VisuPlusWebApp.WebView.Controls
{
    public interface IAnlagenbildElement
    {
        Action<AnlagenbildElementClicked> Clicked { set; get; }
        int Id { set; get; }
        Node DisplayElement { get; private set; }

        void Update(ControlPictureUpdateVar var);

        void Resize(float scaleX, float scaleY);
        void SetZIndex(int index);
    }

    public class AnlagenbildElementClicked
    {
        public enum BildEventTypes
        {
            ChangeValue = 1,
            OpenWindow = 2,
            OpenMiniWindow = 3,
            Toggle = 4,
            TriggerHold = 5,
            TriggerReleased = 6,
        };

        public int ElementId { set; get; }
        public BildEventTypes BildEventType { set; get; }
        public string OpenWindowId { set; get; }

        public AnlagenbildElementClicked(int elementId, BildEventTypes bildEventType)
        {
            ElementId = elementId;
            BildEventType = bildEventType;
        }
        
    }
}
