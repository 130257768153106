using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class SettingsWindow
    {
        private ToolWindow _toolWindow { set; get; }

        private Classes.IServerConnection _serverConnection;
        
        private MainWindow _mainWindow;

        private HTMLDivElement _settingsPane;

        private HTMLInputElement _scaleSettingsElement;
        private HTMLInputElement _playBingElement;

        private HTMLSelectElement _windowAfterLogoutSelection;
        //private HTMLInputElement _windowAfterLogoutFullscreen;

        public SettingsWindow(MainWindow mainWindow, IServerConnection serverConnection)
        {
            _mainWindow = mainWindow;
            _serverConnection = serverConnection;

            _toolWindow = new ToolWindow(mainWindow, "Einstellungen", "settings_window", 600, 200);
            
            _toolWindow.MakeCloseable();
            _toolWindow.Closed += () =>
            {
                Overlay.Hide();
            };
            _settingsPane = new HTMLDivElement();

            _scaleSettingsElement = new HTMLInputElement()
            {
                Type = InputType.Number
            };
            _scaleSettingsElement.ValueAsNumber = Settings.WindowScaling;


            _playBingElement = new HTMLInputElement()
            {
                Type = InputType.Checkbox
            };            
            _playBingElement.Checked = Settings.PlayBing;


            _windowAfterLogoutSelection = new HTMLSelectElement()
            {
                
            };
            _windowAfterLogoutSelection.AppendChild(new HTMLOptionElement()
            {
                Text = "Kein",
                Value = ""
            });

            _serverConnection.SystemTreeLinkGetAll((Action<SystemTreeLink[]>)(links =>
            {
                foreach(var link in links)
                {
                    var lnkOpt = new HTMLOptionElement()
                    {
                        Text = link.Name + (string.IsNullOrWhiteSpace(link.Description) ? "" : " (" + link.Description + ")"),
                        Value = link.Id.ToString()
                    };
                    if (Settings.WindowAfterLogoutId.Equals(lnkOpt.Value))
                    {
                        lnkOpt.Selected = true;
                    }
                    _windowAfterLogoutSelection.AppendChild(lnkOpt);
                }

            }), (Action<string>)(error =>
            {
                _windowAfterLogoutSelection.AppendChild(new HTMLOptionElement()
                {
                    Text = "Fehler",
                    Value = ""
                });
            }));


            //_windowAfterLogoutFullscreen = new HTMLInputElement()
            //{
            //    Type = InputType.Checkbox
            //};
            //_windowAfterLogoutFullscreen.Checked = Settings.WindowAfterLogoutFullscreen;

            HTMLTableElement settingsTable = new HTMLTableElement();
            HTMLTableRowElement scaleSettingRow = settingsTable.InsertRow();
            var cellScalingText = scaleSettingRow.InsertCell();
            cellScalingText.TextContent = "Fensterskalierung";
            var cellScalingValue = scaleSettingRow.InsertCell();
            cellScalingValue.AppendChild(_scaleSettingsElement);

            HTMLTableRowElement playBingSettingsRow = settingsTable.InsertRow();
            var cellPlayBingText = playBingSettingsRow.InsertCell();
            cellPlayBingText.TextContent = "Spiele Ping Sound";
            var cellPlayBingValue = playBingSettingsRow.InsertCell();
            cellPlayBingValue.AppendChild(_playBingElement);

            HTMLTableRowElement windowAfterLogoutRow = settingsTable.InsertRow();
            var cellWindowAfterLogoutText = windowAfterLogoutRow.InsertCell();
            cellWindowAfterLogoutText.TextContent = "Anlagenbild nach Abmelden";
            var cellWindowAfterLogoutValue = windowAfterLogoutRow.InsertCell();
            
            cellWindowAfterLogoutValue.AppendChild(_windowAfterLogoutSelection);
            //var wndAfterLogoutLbl = new HTMLLabelElement()
            //{
            //};
            //wndAfterLogoutLbl.AppendChild(_windowAfterLogoutFullscreen);
            //wndAfterLogoutLbl.AppendChild(new Text("Vollbild"));
            //cellWindowAfterLogoutValue.AppendChild(wndAfterLogoutLbl);
            

            




            _settingsPane.AppendChild(settingsTable);

            HTMLButtonElement saveButton = new HTMLButtonElement()
            {
                TextContent = "Speichern",
                OnClick = @event =>
                {
                    SaveData();
                }
            };
            _settingsPane.AppendChild(saveButton);


            _toolWindow.GetContendArea().AppendChild(_settingsPane);
            
            Overlay.Show();
            Functions.CenterWindow(this._toolWindow.GetHTMLObject());
        }



        private void SaveData()
        {
            if (_scaleSettingsElement.ValueAsNumber >= 1)
            {
                Settings.WindowScaling = (int)_scaleSettingsElement.ValueAsNumber;
            }            
            else
            {
                Window.Alert("Der Skalierungsfaktor muss größer als null sein");
                return;
            }

            Settings.PlayBing = _playBingElement.Checked;


            Settings.WindowAfterLogoutId = (_windowAfterLogoutSelection.ChildNodes[_windowAfterLogoutSelection.SelectedIndex] as HTMLOptionElement).Value;
            //Settings.WindowAfterLogoutFullscreen = _windowAfterLogoutFullscreen.Checked;

            _toolWindow.CloseWindow();

        }


    }
}
