using System;
using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using VisuPlusWebApp.Classes;
using VisuPlusWebApp.WebView.Controls;

namespace VisuPlusWebApp.WebView
{
    public class App
    {
        [Ready]
        public static void Main()
        {
            //load api key and init settings
            //string apiKey = Settings.ApiKey;

            RestServerConnection.GetAppVersion((Action<string>)(s =>
            {
                //compare versions
                if (!Settings.AppVersion.Equals(s))
                {
                    //update version
                    Settings.AppVersion = s;
                    //reload hard
                    Window.Location.Reload(true);
                    
                }
            }), (Action<string>)(err => { }));


            RestServerConnection.VisuPlusGetApiKey((Action<string>)(s =>
            {
                new App(s);
            }),
(Action<string>)(                     err =>
                     {
                         Window.Alert("Fehler beim abrufen des Api-Keys" + err);
                     }));
        }


        public App(string apiKey)
        {
            IServerConnection serverConnection = new RestServerConnection(apiKey);
            new MainWindow(serverConnection);

        }
    }
}