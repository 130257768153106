using Bridge.Html5;

namespace VisuPlusWebApp.WebView.Controls
{
    public class MessageBox
    {
        public enum MessageBoxTypes
        {
            Info =1,
            Error = 2
        }
        public static void Show(string message, MessageBoxTypes type, int duration, HTMLDivElement mother)
        {
            MessageBox box = new MessageBox(message, type, mother);
            Window.SetTimeout((System.Action)(() => { box.Close(); }), duration);
            
        }


        private HTMLDivElement _box;
        private HTMLAnchorElement _closeLink;
        private HTMLDivElement _motherWindow;

        public MessageBox(string message, MessageBoxTypes type, HTMLDivElement mother)
        {
            _motherWindow = mother;
            _box = new HTMLDivElement()
            {
                ClassName = "messagebox"
            };

            if (type == MessageBoxTypes.Error)
            {
                _box.ClassName += " messagebox_error";
            }
            else if (type == MessageBoxTypes.Info)
            {
                _box.ClassName += " messagebox_info";
            }

            _closeLink = new HTMLAnchorElement()
            {
                TextContent = "X",
                ClassName = "messagebox_close",
                OnClick = @event =>
                {
                    Close();
                }
            };
            

            _box.TextContent = message;
            _box.AppendChild(_closeLink);

            _motherWindow.AppendChild(_box);

        }

        public void Close()
        {
            _motherWindow.RemoveChild(_box);
        }
        

    }
}
