using System;
using System.Collections.Generic;
using System.Linq;
using Bridge.Html5;

namespace VisuPlusWebApp.WebView.Controls
{
    public class MovableTable : IDisposable
    {
        public class MovableTableColumn
        {
            public enum ColumnTypes
            {
                Digit = 1,
                Text = 2,
                Date = 3
            }
            public string Text { set; get; }
            public int Width { set; get; }
            public string ForeColor { set; get; }
            public string BackColor { set; get; }
            public ColumnTypes ColumnType { set; get; }
            public bool IsVisible { set; get; }

            public MovableTableColumn(string text)
            {
                Text = text;
                Width = 100;
                ForeColor = "";
                BackColor = "";
                ColumnType = ColumnTypes.Text;
                IsVisible = true;
            }
        }

        public class MovableTableRow
        {
            public System.Action DoubleClicked;
            public System.Action RightClicked;

            public HTMLTableRowElement HtmlRow { set; get; }
            private readonly List<MovableTableCell> _cells = new List<MovableTableCell>();

            public List<MovableTableCell> Cells
            {
                set
                {
                    _cells.Clear();
                    _cells.AddRange(value);
                }
                get { return _cells; }
            }

            public string BackColor { set; get; }
            public string ForeColor { set; get; }


            public bool IsSelected { get; set; }

            public MovableTableRow()
            {
                ForeColor = "";
                BackColor = "";


            }

            public MovableTableRow(List<MovableTableCell> cells)
            {
                Cells = cells;
                ForeColor = "";
                BackColor = "";
            }

            public void AddCell(MovableTableCell cell)
            {
                _cells.Add(cell);
            }

            public void AddCell(string text)
            {
                _cells.Add(new MovableTableCell(text));
            }

        }
        
        public class MovableTableCell
        {
            public bool IsText = true;
            public HTMLTableDataCellElement HtmlCell { set; get; }
            public string Text { set; get; }
            public string BackColor { set; get; }
            public string ForeColor { set; get; }
            public Node Node { set; get; }

            public MovableTableCell(string text)
            {
                Text = text;
                ForeColor = "";
                BackColor = "";
                IsText = true;
            }
            public MovableTableCell(Node node)
            {
                Node = node;
                ForeColor = "";
                BackColor = "";
                IsText = false;
            }
        }

        public HTMLTableElement HTMLElement
        {
            get { return _tableElement; }
        }

        public IEnumerable<MovableTableColumn> Columns
        {
            get { return _dataColumns; }
        }

        public IEnumerable<MovableTableRow> Rows
        {
            get { return _dataRows; }
        }

        private enum SortOrders
        {
            None = 1,
            Asc = 2,
            Desc = 3
        }

        private HTMLTableElement _tableElement;
        private HTMLTableSectionElement _tableHead;
        private HTMLTableSectionElement _tableBody;


        private List<MovableTableColumn> _dataColumns = new List<MovableTableColumn>();
        private List<MovableTableRow> _dataRows = new List<MovableTableRow>();

        private bool _isResizable = false;
        private bool _isMovable = false;
        private bool _isSortable = false;
        private bool _isSelectable = false;

        private bool _cntrlPressed = false;
        private bool _isUpdating = false;
        private bool _isResizing = false;
        private bool _isMoving = false;

        private bool _headUpdated = false;

        private SortOrders _sortOrder = SortOrders.None;
        private int _sortCell = -1;
        private MovableTableColumn.ColumnTypes _sortDataType = MovableTableColumn.ColumnTypes.Text;

        private int _resizeStartX = 0;
        private HTMLTableDataCellElement _resizeCell;



        public MovableTable(string id, string classes)
        {
            _tableElement = new HTMLTableElement()
            {
                Id = id,
                ClassName = classes,
                InnerHTML = "<thead></thead><tbody></tbody>"
            };
            Window.OnMouseMove += MouseMove;
            Window.OnMouseUp += OnMouseUp;
            Window.OnKeyDown += OnKeyDown;
            Window.OnKeyUp +=  OnKeyUp;
            //_tableElement.CreateTHead();
            _tableHead = _tableElement.THead;
            //_tableElement.InsertRow(0);
            //_tableElement.InsertRow();
            _tableBody = System.Linq.Enumerable.First<HTMLTableSectionElement>(_tableElement.TBodies);
            //_tableElement.DeleteRow(-1);
            _headUpdated = true;
        }

        private void OnKeyUp(KeyboardEvent keyboardEvent)
        {
            _cntrlPressed = false;
        }

        private void OnKeyDown(KeyboardEvent keyboardEvent)
        {
            if (keyboardEvent.CtrlKey)
            {
                _cntrlPressed = true;
            }
        }

        //private void tet()
        //{
        //    var htmlTable = new HTMLTableElement();
        //    //create head
        //    htmlTable.CreateTHead();
        //    //get head
        //    var tableHead = htmlTable.THead;
        //    //create a body
        //    htmlTable.InsertRow(-1);
            
        //    //print innerHtml 
        //    Console.WriteLine(htmlTable.InnerHTML);

        //    //get first body
        //    var tableBody = htmlTable.TBodies.FirstOrDefault();
        //    htmlTable.DeleteRow(-1);


        //    //add to head
        //    var testhead = tableHead.InsertRow();
        //    testhead.InnerHTML = "test head";

        //    //add to body
        //    var testrow = tableBody.InsertRow();
        //    testrow.InnerHTML = "Test";

        //    Document.Body.AppendChild(htmlTable);
        //}


        public void Dispose()
        {
            Window.OnMouseMove -= MouseMove;
            Window.OnMouseUp -= OnMouseUp;
            Window.OnKeyDown -= OnKeyDown;
            Window.OnKeyUp -= OnKeyUp;
        }

        public void AddColumnsRange(IEnumerable<MovableTableColumn> columns)
        {
            _dataColumns.AddRange(columns);
            _headUpdated = true;
            Paint();
        }

        public void AddColumn(MovableTableColumn column)
        {
            _dataColumns.Add(column);
            _headUpdated = true;
            Paint();
        }

        public void AddColumn(string text, int width = -1)
        {
            var column = new MovableTableColumn(text);
            if (width >= 0)
            {
                column.Width = width;
            }
            AddColumn(column);
        }
        

        public void ClearColumns()
        {
            _dataColumns.Clear();
            _headUpdated = true;
            Paint();
        }

        public void AddRowsRange(IEnumerable<MovableTableRow> rows)
        {
            _dataRows.AddRange(rows);
            Paint();
        }

        public void AddRow(MovableTableRow row)
        {
            _dataRows.Add(row);
            Paint();
        }

        public void ClearRows()
        {
            _dataRows.Clear();
            Paint();
        }

        public void RemoveRow(MovableTableRow row)
        {
            _dataRows.Remove(row);
            Paint();
        }
        public void RemoveRows(IEnumerable< MovableTableRow> rows)
        {
System.Linq.Enumerable.ToList<MovableTableRow>(            rows).ForEach((Action<MovableTableRow>)(row => _dataRows.Remove(row)));
            Paint();
        }

        public IEnumerable<MovableTableRow> SelectedRows
        {
            get
            {
                var rows = new List<MovableTableRow>();
                foreach (var movableTableRow in _dataRows)
                {
                    if (movableTableRow.IsSelected)
                    {
                        rows.Add(movableTableRow);
                    }
                }
                return rows;
            }

        }

        public void BeginUpdate()
        {
            _isUpdating = true;
        }

        public void EndUpdate()
        {
            _isUpdating = false;
            Paint();
        }

        public void AllowResizeColumns()
        {
            _isResizable = true;
            _headUpdated = true;
            Paint();

        }

        public void UpdateAll()
        {
            this._headUpdated = true;
            Paint();
        }

        public void AllowDragColumns()
        {
        }

        public void AllowSortColumns()
        {
            _isSortable = true;
            _headUpdated = true;
            Paint();
        }

        public void AllowSelectColumns()
        {
            _isSelectable = true;
            Paint();
        }

        private void Paint()
        {
            if (_isUpdating) { return; }

            //#### Update Head
            if (_headUpdated)
            {
                _headUpdated = false;
                _sortCell = -1;
                _sortOrder = SortOrders.None;
                _sortDataType = MovableTableColumn.ColumnTypes.Text;
                _tableHead.TextContent = "";
                var row = _tableHead.InsertRow();

                foreach (var movableTableColumn in _dataColumns)
                {
                    if (!movableTableColumn.IsVisible) { continue; }
                    var cell = row.InsertCell();
                    var textSpan = new HTMLSpanElement()
                    {
                        TextContent = movableTableColumn.Text,
                        ClassName = "mvtbl_txt_spn"
                    };

                    if (movableTableColumn.BackColor != "")
                    {
                        cell.Style.SetProperty("background-color", movableTableColumn.BackColor);
                    }
                    if (movableTableColumn.ForeColor != "")
                    {
                        cell.Style.SetProperty("color", movableTableColumn.ForeColor);
                    }
                    cell.Style.SetProperty("width", movableTableColumn.Width + "px");

                    if (_isSortable)
                    {
                        textSpan.Style.SetProperty("cursor", "pointer");
                        textSpan.OnClick += @event =>
                        {
                            SortTable(cell.CellIndex, movableTableColumn.ColumnType);
                        };
                    }
                    cell.AppendChild(textSpan);
                    if (_isResizable)
                    {
                        HTMLSpanElement resizeDiv = new HTMLSpanElement()
                        {
                            ClassName = "mvtbl_rsz_spn"
                        };
                        resizeDiv.OnMouseDown += @event =>
                        {
                            StartRezise(cell, (@event.As<MouseEvent>()).ScreenX);
                        };
                        cell.AppendChild(resizeDiv);
                    }

                }
            }

            //#####update data

            //remove all
            _tableBody.InnerHTML = "";
            //Console.WriteLine("i have " + _tableBody.Rows.Length + " rows");
            //foreach (var row in _tableBody.Rows)
            //{
            //    Console.WriteLine("i delete  " + row.RowIndex + "");
            //    _tableBody.DeleteRow(row.RowIndex);
            //}

            //sort 
            MovableTableRow[] sortedRows;
            if (_sortOrder == SortOrders.Asc)
            {
                sortedRows = System.Linq.Enumerable.OrderBy<MovableTableRow,string>(_dataRows,(Func<MovableTableRow,string>)(dataRow => dataRow.Cells[_sortCell].Text), new RowComparer(_sortDataType)).ToArray();
            }
            else if (_sortOrder == SortOrders.Desc)
            {
                sortedRows = System.Linq.Enumerable.OrderByDescending<MovableTableRow,string>(_dataRows,(Func<MovableTableRow,string>)(dataRow => dataRow.Cells[_sortCell].Text), new RowComparer(_sortDataType)).ToArray();
            }
            else
            {
                sortedRows = _dataRows.ToArray();
            }
            bool odd = true;
            foreach (var movableTableRow in sortedRows)
            {
                movableTableRow.IsSelected = false;
                var newRow = _tableBody.InsertRow();
                movableTableRow.HtmlRow = newRow;


                newRow.OnClick += @event =>
                {
                    MouseEvent mEv = @event.As<MouseEvent>();
                    if (mEv.Button == 2)
                    {
                        if (movableTableRow.RightClicked != null) { movableTableRow.RightClicked(); }
                    }
                    if (_isSelectable)
                    {
                            //if cntrl not pressed unselect all other
                            if (!_cntrlPressed)
                        {
                            foreach (var row in _dataRows)
                            {
                                if (row.IsSelected && row != movableTableRow)
                                {
                                    row.HtmlRow.ClassList.Remove("mvtbl_row_selected");
                                    row.IsSelected = false;
                                }
                            }
                        }
                        if (movableTableRow.IsSelected)
                        {
                            newRow.ClassList.Remove("mvtbl_row_selected");
                            movableTableRow.IsSelected = false;
                        }
                        else
                        {
                            newRow.ClassList.Add("mvtbl_row_selected");
                            movableTableRow.IsSelected = true;
                        }
                    }

                };
                newRow.OnDblClick += @event =>
                {
                    MouseEvent mEv = @event.As<MouseEvent>();
                    if (mEv.Button == 0)
                    {
                        if (movableTableRow.DoubleClicked != null) { movableTableRow.DoubleClicked(); }
                    }
                };
                newRow.ClassName = "mvtbl_row " + (odd ? "mvtbl_row_odd" : "mvtbl_row_even");
                if (movableTableRow.BackColor != "")
                {
                    newRow.Style.SetProperty("background-color", movableTableRow.BackColor);
                }
                if (movableTableRow.ForeColor != "")
                {
                    newRow.Style.SetProperty("color", movableTableRow.ForeColor);
                }
                for (int i = 0; i < movableTableRow.Cells.Count; i++)
                {
                    var movableTableCell = movableTableRow.Cells[i];
                    if (!_dataColumns[i].IsVisible) { continue; }
                
                    var newCell = newRow.InsertCell();
                    movableTableCell.HtmlCell = newCell;
                    if (movableTableCell.IsText)
                    {
                        newCell.TextContent = movableTableCell.Text;
                        if (movableTableCell.BackColor != "")
                        {
                            newCell.Style.SetProperty("background-color", movableTableCell.BackColor);
                        }
                        if (movableTableRow.ForeColor != "")
                        {
                            newCell.Style.SetProperty("color", movableTableCell.ForeColor);
                        }
                    }
                    else
                    {
                        newCell.AppendChild(movableTableCell.Node);
                    }
                }

                odd = !odd;
            }

        }

        private void ReziseCellMouseDown(MouseEvent<HTMLSpanElement> mouseEvent)
        {
            throw new NotImplementedException();
        }

        private void SortTable(int byCell, MovableTableColumn.ColumnTypes sortDataType)
        {
            //sort by different Cell
            if (byCell != _sortCell)
            {
                _sortCell = byCell;
                _sortDataType = sortDataType;
                _sortOrder = SortOrders.None; //set this so it gets asc
            }

            //set new order
            if (_sortOrder == SortOrders.None) _sortOrder = SortOrders.Asc;
            else if (_sortOrder == SortOrders.Asc) _sortOrder = SortOrders.Desc;
            else _sortOrder = SortOrders.None;

            Paint();


        }

        private void OnMouseUp(MouseEvent mouseEvent)
        {
            _isResizing = false;
        }

        private void MouseMove(MouseEvent mouseEvent)
        {
            if (_isResizing)
            {
                int newWidth = mouseEvent.ScreenX - _resizeStartX;
                _resizeCell.Style.SetProperty("width", newWidth + "px");

            }
        }



        private void StartRezise(HTMLTableDataCellElement cell, int mouseStartX)
        {
            _resizeCell = cell;
            _resizeStartX = mouseStartX - cell.ClientWidth;
            _isResizing = true;
        }

        private class RowComparer : IComparer<string>
        {
            private MovableTableColumn.ColumnTypes _sortDataType;

            public RowComparer(MovableTableColumn.ColumnTypes sortDataType)
            {
                _sortDataType = sortDataType;
            }

            public int Compare(string x, string y)
            {
                if (_sortDataType == VisuPlusWebApp.WebView.Controls.MovableTable.MovableTableColumn.ColumnTypes.Date)
                {
                    DateTime dX, dY;
                    bool pX, pY;
                    pX = DateTime.TryParse(x, out dX);
                    pY = DateTime.TryParse(y, out dY);
                    
                    //both not parseable
                    if (!pX && !pY)
                    {
                        return string.Compare(x, y);
                    }
                    //x is parseable and y not
                    else if (pX && !pY)
                    {
                        return 1;
                    }
                    //y is parseable and x not
                    else if (!pX && pY)
                    {
                        return -1;
                    }
                    else //both make int compare
                    {
                        return dX.CompareTo(dY);
                    }
                }
                else if (_sortDataType == VisuPlusWebApp.WebView.Controls.MovableTable.MovableTableColumn.ColumnTypes.Digit)
                {
                    int iX, iY;
                    bool pX, pY;
                    pX = int.TryParse(x, out iX);
                    pY = int.TryParse(y, out iY);

                    //both not parseable
                    if (!pX && !pY)
                    {
                        return string.Compare(x, y);
                    }
                    //x is parseable and y not
                    else if (pX && !pY)
                    {
                        return 1;
                    }
                    //y is parseable and x not
                    else if (!pX && pY)
                    {
                        return -1;
                    }
                    else //both make int compare
                    {
                        return iX - iY;
                    }
                }
                else
                {
                    return string.Compare(x, y);
                }
            }
        }

        private void ResizeCell()
        {

        }

    }
}
