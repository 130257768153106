using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class LogsWindow
    {
        private ToolWindow _toolWindow { set; get; }


        private MainWindow _mainWindow;

        private HTMLDivElement _logItems;
        private HTMLDivElement _logData;

        private IServerConnection _serverConnection;

        public LogsWindow(MainWindow mainWindow, IServerConnection serverConnection)
        {
            _mainWindow = mainWindow;
            _serverConnection = serverConnection;

            _toolWindow = new ToolWindow(mainWindow, "Logs", "logs_window", 800, 500);

            _toolWindow.MakeCloseable();
            _toolWindow.MakeResizable();
            _toolWindow.MakeDraggable();

            _logItems = new HTMLDivElement()
            {
                ClassName = "fieldset_overflow_div"
            };
            _logData = new HTMLDivElement()
            {
                Id = "log_window_data_contend",
                ClassName = "fieldset_overflow_div"
            };


            var logsListFieldSet = new HTMLFieldSetElement()
            {
                Id = "log_window_list_fieldset"
            };
            logsListFieldSet.AppendChild(
                new HTMLLegendElement()
                {
                    TextContent = "Dateien:"
                });
            logsListFieldSet.AppendChild(_logItems);

             var logDataFieldset = new HTMLFieldSetElement()
            {
                Id = "log_window_data_fieldset"
            };
            logDataFieldset.AppendChild(
                new HTMLLegendElement()
                {
                    TextContent = "Log:"
                });
            logDataFieldset.AppendChild(_logData);

            _toolWindow.GetContendArea().AppendChild(logsListFieldSet);
            _toolWindow.GetContendArea().AppendChild(logDataFieldset);

            Functions.CenterWindow(this._toolWindow.GetHTMLObject());
            
            _serverConnection.LogsGetFiles((Action<LogFileInfo[]>)FillLogList, (Action<string>)(s => { }));
        }
        

        private void FillLogList(IEnumerable<LogFileInfo> files)
        {
            foreach (var file in files)
            {
                HTMLAnchorElement link = new HTMLAnchorElement()
                {
                    TextContent = file.Text,
                    OnClick = @event =>
                    {
                        GetLog(file.Name);
                    },
                    Href = "#"
                };
                _logItems.AppendChild(link);
                _logItems.AppendChild(new HTMLBRElement());
            }
        }

        private void GetLog(string name)
        {

            _serverConnection.LogsGetFileData(name, (Action<string>)(s =>
            {
                _logData.InnerHTML = s.Replace(Environment.NewLine, "<br>").Replace("\t", "&emsp;");
            }), (Action<int>)(tr =>
            {
                _logData.TextContent = "Empfangen: " + tr + "%";
            }), (Action<string>)(err =>
            {
                _logData.InnerHTML = "Fehler<br>"+ err;
            }));
        }



    }
}
