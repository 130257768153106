using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;
using Bridge.Html5;
using Bridge.jQuery2;
using RGAVerl.VisuPlus2000net.Models;
using VisuPlusWebApp.Classes;

namespace VisuPlusWebApp.WebView.Controls
{
    public class MainWindow
    {
        //private HTMLBodyElement _mainBody;
        private HTMLDivElement _mainWindow;
        private HTMLDivElement _contendArea;
        private HTMLDivElement _menuArea;
        private HTMLDivElement _footerArea;


        private LoginWindow _loginWindow;
        private FaultListWindow _faultListWindow;
        private SystemTreeWindow _systemTreeWindow;
        private ProjectImageWindow _projectImageWindow;

        private IServerConnection _serverConnection;
        private FaultList _faultList;

        private HTMLAnchorElement _linkSystemTree;
        private HTMLAnchorElement _linkFaults;
        private HTMLAnchorElement _linkLoginOut;
        private HTMLAnchorElement _linkTrend;
        private HTMLAnchorElement _linkLogs;
        private HTMLAnchorElement _linkSettings;

        private HTMLSpanElement _footerUserInfo;
        private HTMLSpanElement _footerFaultInfo;

        private List<ToolWindow> _openToolWindows = new List<ToolWindow>();


        private ToolWindow _topMostWindow;

        public HTMLDivElement ContendArea
        {
            get { return _contendArea; }
        }

        public bool HasUserRight(UserRights right)
        {
            return _loggedInUser != null && Functions.HasUserRight(_loggedInUser, right);

        }

        public int LoggedInUserId
        {
            get
            {
                if (_loggedInUser != null) return _loggedInUser.Id;
                else return -1;
            }
        }

        public string LoggedInUserName
        {
            get
            {
                if (_loggedInUser != null) return _loggedInUser.Name;
                else return string.Empty;
            }
        }

        
        private User _loggedInUser = null;


        public HTMLDivElement MainWindowDiv
        {
            get { return _mainWindow; }
        }


        public MainWindow(IServerConnection serverConnection)
        {
            _serverConnection = serverConnection;
            //_mainBody = Document.Body as HTMLBodyElement;
            _mainWindow = Document.GetElementById("main") as HTMLDivElement;
            _contendArea = Document.GetElementById("contend") as HTMLDivElement;
            _menuArea = Document.GetElementById("menubar") as HTMLDivElement;
            _footerArea = Document.GetElementById("footer") as HTMLDivElement;
            Overlay.CreateInstance(this);

            AddNavigationButtons();
            AddInfoElements();
            _projectImageWindow = new ProjectImageWindow(this);

            _serverConnection.VisuPlusGetProjectImage((Action<object>)(s =>
            {
                _projectImageWindow.SetBackImageFile(s);
            }), (Action<string>)(err => { }));

            _serverConnection.VisuPlusGetProjectInfo((Action<ProjectInfo>)(s =>
            {
                HTMLParagraphElement text = Document.GetElementById("head_text") as HTMLParagraphElement;
                text.TextContent = s.ProjectName;

                _loginWindow = new LoginWindow(this, s.LoginList, _serverConnection);
                _loginWindow.LoggedIn += CheckLogin;
                _loginWindow.LoadUserList();
                _loginWindow.ShowWindow();
                _loginWindow.CenterWindow();

                    StartUserSession();
            }), (Action<string>)(err => { MessageBox.Show("Fehler beim laden der Projektdaten", MessageBox.MessageBoxTypes.Error, 5000, this.MainWindowDiv); }));



            _faultList = new FaultList(_serverConnection);
            _faultList.Updated += Updated;
            _faultList.UpdateAll += UpdateAll;

            _faultList.UpdateError += () =>
            {
                MessageBox.Show("Fehler beim Updaten der Störungen!!", MessageBox.MessageBoxTypes.Error, 3000, this._mainWindow);
            };

            _faultListWindow = new FaultListWindow(this, _faultList);

            _systemTreeWindow = new SystemTreeWindow(this, _serverConnection);


            _systemTreeWindow.HideWindow();
            _faultList.StartUpdate();

            

            Functions.CenterWindow(this._topMostWindow.GetHTMLObject());


            //ie
            jQuery.Select(Window.FrameElement).Bind("beforeprint", (Action)BeforePrintEvent);
            //all other
            Window.MatchMedia("print").AddListener((Action<MediaQueryList>)(list =>
            {
                BeforePrintEvent();
            }));

        }


        private void BeforePrintEvent()
        {
            if (_topMostWindow != null)
            {
                _topMostWindow.Resized();
            }
        }


        private void AddNavigationButtons()
        {
            _linkSystemTree = new HTMLAnchorElement()
            {
                Title = "Anlagenbaum",
                Href = "#",
                Id = "menu_button_anlagenbaum",
                ClassName = "menu_button",
                OnClick = OnClickShowSystemTree
            };
            _linkSystemTree.AppendChild(new HTMLImageElement()
            {
                ClassName = "menu_image",
                Src = "img/glyphicons/glyphicons-145-folder-open.png"
            });
            
            _linkFaults = new HTMLAnchorElement()
            {
                Title = "Störungen",
                Href = "#",
                Id = "menu_button_faults",
                ClassName = "menu_button",
                OnClick = OnClickFaultLink
            };
            _linkFaults.AppendChild(new HTMLImageElement()
            {
                ClassName = "menu_image menu_image_enabled",
                Src = "img/glyphicons/glyphicons-79-warning-sign.png"
            });


            _linkLoginOut = new HTMLAnchorElement()
            {
                Title = "Login/Logout",
                Href = "#",
                Id = "menu_button_loginout",
                ClassName = "menu_button",
                OnClick = OnClickLoginOut
            };
            _linkLoginOut.AppendChild(new HTMLImageElement()
            {
                ClassName = "menu_image menu_image_enabled",
                Src = "img/glyphicons/glyphicons-45-keys.png"
            });


            _linkTrend = new HTMLAnchorElement()
            {
                Title = "Trend",
                Href = "#",
                Id = "menu_button_trend",
                ClassName = "menu_button",
                OnClick = OnClickShowTrend
            };
            _linkTrend.AppendChild(new HTMLImageElement()
            {
                ClassName = "menu_image",
                Src = "img/glyphicons/glyphicons-42-charts.png"
            });

            _linkLogs = new HTMLAnchorElement()
            {
                Title = "Logs",
                Href = "#",
                Id = "menu_button_logs",
                ClassName = "menu_button",
                OnClick = OnClickShowLogs
            };
            _linkLogs.AppendChild(new HTMLImageElement()
            {
                ClassName = "menu_image",
                Src = "img/glyphicons/glyphicons-530-list-alt.png"
            });

            _linkSettings = new HTMLAnchorElement()
            {
                Title = "Einstellungen",
                Href = "#",
                Id = "menu_button_settings",
                ClassName = "menu_button",
                OnClick = OnClickShowSettings
            };
            _linkSettings.AppendChild(new HTMLImageElement()
            {
                ClassName = "menu_image menu_image_enabled",
                Src = "img/glyphicons/glyphicons-281-settings.png"
            });
            _menuArea.AppendChild(_linkSystemTree);
            _menuArea.AppendChild(_linkFaults);
            _menuArea.AppendChild(_linkLoginOut);
            _menuArea.AppendChild(_linkTrend);
            _menuArea.AppendChild(_linkLogs);
            _menuArea.AppendChild(_linkSettings);

        }

        private void OnClickShowLogs(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            if (!HasUserRight(UserRights.HasLogs))
            {
                return;
            }
            LogsWindow window = new LogsWindow(this, _serverConnection);
        }

        private void OnClickShowTrend(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            if (!HasUserRight(UserRights.HasTrend))
            {
                return;
            }
            TrendWindow window = new TrendWindow(this, _serverConnection);
        }

        private void OnClickShowSettings(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            SettingsWindow window = new SettingsWindow(this, _serverConnection);
        }


        private void OnClickShowSystemTree(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            if (!HasUserRight(UserRights.HasSystemTree))
            {
                return;
            }
            if (_systemTreeWindow.IsVisible())
            {
                _systemTreeWindow.HideWindow();
            }
            else
            {
                _systemTreeWindow.ShowWindow();
            }
        }

        private void AddInfoElements()
        {
            _footerFaultInfo = new HTMLSpanElement()
            {
                Id = "footer_fault_info",
                OnClick = @event =>
                {
                    if (_faultListWindow.IsVisible())
                    {
                        _faultListWindow.HideWindow();
                    }
                    else
                    {
                        _faultListWindow.ShowWindow();

                        _faultListWindow.CenterWindow();
                    }
                }
            };
            _footerArea.AppendChild(_footerFaultInfo);

            _footerUserInfo = new HTMLSpanElement()
            {
                Id = "footer_user_info",
                TextContent = "Abgemeldet"
            };
            _footerArea.AppendChild(_footerUserInfo);


        }


        private void OnClickFaultLink(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            if (_faultListWindow.IsVisible())
            {
                _faultListWindow.HideWindow();
            }
            else
            {
                _faultListWindow.ShowWindow();
                _faultListWindow.CenterWindow();
            }
        }




        public void AddElementToContend(Node n)
        {
            _contendArea.AppendChild(n);
        }

        public void AddWindow(ToolWindow window)
        {
            _openToolWindows.Add(window);
            _contendArea.AppendChild(window.GetHTMLObject());
            if (window.Position.X == 0 && window.Position.Y == 0)
            {
                window.Position = GetWindowPosition();
            }
            BringWindowToFront(window);
        }

        public void RemoveWindow(ToolWindow window)
        {
            _openToolWindows.Remove(window);
            _contendArea.RemoveChild(window.GetHTMLObject());
        }

        public void BringWindowToFront(ToolWindow window)
        {
            //sort windows with zindex
            var sortedWindows = System.Linq.Enumerable.OrderBy<HTMLElement,HTMLElement>(_contendArea.Children,(Func<HTMLElement,HTMLElement>)(wnd => wnd), new WindowIndexComparer());
            _topMostWindow = null;
            int highestZIndex = 0;
            int zIndx = 0;
            foreach (var sortedWindow in sortedWindows)
            {
                zIndx++;
                int innerIndx = 0;
                if (int.TryParse(Window.GetComputedStyle(sortedWindow).ZIndex, out innerIndx))
                {
                    //higher than 500 are overlay-windows
                    if (innerIndx > 500)
                    {
                        //get top most
                        if (highestZIndex < innerIndx)
                        {
                            highestZIndex = innerIndx;
                        }
                        continue;
                    }
                }
                sortedWindow.ClassList.Remove("topmostwindow");
                if (sortedWindow.IsEqualNode(window.GetHTMLObject()))
                {
                    sortedWindow.Style.SetProperty("z-index", "500");
                    //get top most
                    if (highestZIndex < 500)
                    {
                        highestZIndex = zIndx;
                        _topMostWindow = window;
                    }
                }
                else
                {
                    sortedWindow.Style.SetProperty("z-index", zIndx.ToString());

                }
            }
            if (_topMostWindow != null)
            {
                (_topMostWindow.GetHTMLObject() as HTMLDivElement).ClassList.Add("topmostwindow");

            }
        }

        public class WindowIndexComparer : IComparer<HTMLElement>
        {
            public int Compare(HTMLElement e1, HTMLElement e2)
            {
                string s1 = Window.GetComputedStyle(e1).ZIndex;
                string s2 = Window.GetComputedStyle(e2).ZIndex;


                if (IsNumeric(s1) && IsNumeric(s2))
                {
                    if (Convert.ToInt32(s1) > Convert.ToInt32(s2)) return 1;
                    if (Convert.ToInt32(s1) < Convert.ToInt32(s2)) return -1;
                    if (Convert.ToInt32(s1) == Convert.ToInt32(s2)) return 0;
                }

                if (IsNumeric(s1) && !IsNumeric(s2))
                    return -1;

                if (!IsNumeric(s1) && IsNumeric(s2))
                    return 1;

                return string.Compare(s1, s2, true);
            }

            public static bool IsNumeric(object value)
            {
                int i = 0;
                return int.TryParse(value.ToString(), out i);
            }
        }





        private void OnClickLoginOut(MouseEvent<HTMLAnchorElement> mouseEvent)
        {
            Logout();
        }




        private void ResetWindows()
        {
            //close all windows
            foreach (var anlagenbildWindow in _openToolWindows)
            {
                Task.Run((Action)(() =>
                {
                    anlagenbildWindow.CloseWindow();
                }));
            }
            _systemTreeWindow.HideWindow();
            _projectImageWindow.ShowAndCenter();
            _loginWindow.LoadUserList();
            
            _loginWindow.ShowWindow();
            _loginWindow.CenterWindow();

        }



        private void CheckLogin()
        {
            _serverConnection.UserLogin(_loginWindow.UserName, _loginWindow.Password, false, (Action<bool>)(b =>
            {
                if (b)
                {
                    if (_loginWindow.StayLoggedIn)
                    {
                        SaveSession();
                    }
                    CheckUserIsLoggedIn();
                }
                else
                {
                    MessageBox.Show("Benutzer konnte nicht angemeldet werden", MessageBox.MessageBoxTypes.Error, 3000,
                        ContendArea);
                }
            }),
(Action<string>)(                err =>
                {

                }));
        }
        private void SaveSession()
        {
            _serverConnection.UserSaveLogin(
(Action<string>)(            (str) => {
                Classes.Settings.SessionKey = str;
            }), 
(Action<string>)(            s1 => { }));
        }

        /// <summary>
        /// logs out a user and deletes session key
        /// </summary>
        private void Logout()
        {

            ResetWindows();
            if (!string.IsNullOrWhiteSpace(Classes.Settings.SessionKey))
            {
                _serverConnection.UserSessionsDeleteSessionForUser(_loggedInUser.Id, Classes.Settings.SessionKey, (System.Action<bool>)((arg) => {
                    _serverConnection.UserLogout((Action<bool>)((b) => CheckUserIsLoggedIn()), (Action<string>)(err => { }));
                }), (System.Action<string>)((arg) => {
                    _serverConnection.UserLogout((Action<bool>)((b) => CheckUserIsLoggedIn()), (Action<string>)(err => { }));
                }));
                Classes.Settings.SessionKey = String.Empty;
            }
            else
            {
                _serverConnection.UserLogout((Action<bool>)((b) => CheckUserIsLoggedIn()), (Action<string>)(err => { }));
            }
        }

        private void ShowWindowAfterLogout()
        {
            if (string.IsNullOrWhiteSpace(Settings.WindowAfterLogoutId)) return;

            //get id
            _serverConnection.SystemTreeLinkGetWindowId(Settings.WindowAfterLogoutId, (Action<string>)(id =>
            {
                OpenAnlagenbild(id);
            }), 
(Action<string>)(            err => { }));


        }

        /// <summary>
        /// first method called
        /// sends user session key to server
        /// calls if a user is logged in afterwards
        /// </summary>
        private void StartUserSession()
        {
            if (string.IsNullOrWhiteSpace(Classes.Settings.SessionKey))
            {
                CheckUserIsLoggedIn();
            }
            else
            {
                _serverConnection.UserLoadLogin(Classes.Settings.SessionKey, (Action<bool>)((b) =>
                {
                    CheckUserIsLoggedIn();
                }),
(Action<string>)(                    err => { }));
            }
        }

        /// <summary>
        /// check is a user is logged in on server
        /// </summary>
        private void CheckUserIsLoggedIn()
        {
            _serverConnection.UserIsLoggedIn((Action<bool>)((b) =>
            {
                GetLoggedInUser(!b);
            }), (Action<string>)(err => { }));
        }

        /// <summary>
        /// takes the logged in user
        /// </summary>
        private void GetLoggedInUser(bool isGuest)
        {
            _serverConnection.UserLoggedInUser((Action<User>)((u) =>
            {
                _loggedInUser = u;
                LoginChanged(isGuest);
            }),
(Action<string>)(                err =>
                {

                }));
        }


        private void LoginChanged(bool isGuest)
        {
            isGuest = isGuest || _loggedInUser == null;
            //set rights
            if (HasUserRight(UserRights.HasSystemTree))
            {
                (_linkSystemTree.FirstChild as HTMLImageElement).ClassList.Remove("menu_image_disabled");
                (_linkSystemTree.FirstChild as HTMLImageElement).ClassList.Add("menu_image_enabled");

                _serverConnection.SessionSystemTreeLoadForUser((Action<bool>)((bool foo) =>
                {
                    if (isGuest)
                    {
                        ShowWindowAfterLogout();
                    }
                    else
                    {
                        _systemTreeWindow.ShowWindow();
                    }
                }), (Action<string>)((error) => { }));
            }
            else
            {
                (_linkSystemTree.FirstChild as HTMLImageElement).ClassList.Add("menu_image_disabled");
                (_linkSystemTree.FirstChild as HTMLImageElement).ClassList.Remove("menu_image_enabled");
            }

            if (HasUserRight(UserRights.HasLogs))
            {
                (_linkLogs.FirstChild as HTMLImageElement).ClassList.Remove("menu_image_disabled");
                (_linkLogs.FirstChild as HTMLImageElement).ClassList.Add("menu_image_enabled");
            }
            else
            {
                (_linkLogs.FirstChild as HTMLImageElement).ClassList.Add("menu_image_disabled");
                (_linkLogs.FirstChild as HTMLImageElement).ClassList.Remove("menu_image_enabled");
            }

            if (HasUserRight(UserRights.HasTrend))
            {
                (_linkTrend.FirstChild as HTMLImageElement).ClassList.Remove("menu_image_disabled");
                (_linkTrend.FirstChild as HTMLImageElement).ClassList.Add("menu_image_enabled");
            }
            else
            {
                (_linkTrend.FirstChild as HTMLImageElement).ClassList.Add("menu_image_disabled");
                (_linkTrend.FirstChild as HTMLImageElement).ClassList.Remove("menu_image_enabled");
            }

            _faultListWindow.SetQuitMode(HasUserRight(UserRights.CanQuitFault));
            _faultListWindow.SetDeleteMode(HasUserRight(UserRights.CanDeleteFault));
            _faultListWindow.SetHistoryButton(HasUserRight(UserRights.HasFaultHistory));

            if (!isGuest)
            {
                _footerUserInfo.TextContent = "" + _loggedInUser.Name + " Angemeldet";
                _loginWindow.HideWindow();
            }
            else
            {
                _footerUserInfo.TextContent = "Abgemeldet";
            }

        }






        private void UpdateAll()
        {
            FaultListUpdated();
        }

        private void Updated(FaultList.FaultListUpdate faultListUpdate)
        {
            FaultListUpdated();

        }
        private void FaultListUpdated()
        {
            int count = _faultList.GetFaultCount();
            if (count == 0)
            {
                _footerFaultInfo.Style.SetProperty("display", "none");
            }
            else if (count == 1)
            {
                _footerFaultInfo.Style.SetProperty("display", "block");
                _footerFaultInfo.TextContent = count + " Störung";
            }
            else
            {
                _footerFaultInfo.Style.SetProperty("display", "block");
                _footerFaultInfo.TextContent = count + " Störungen";
            }
        }


        public void OpenAnlagenbild(string id)
        {
            foreach (var listWindow in _openToolWindows)
            {
                if (listWindow.Id.Equals(AnlagenbildWindow.AnlagenbildIdName + id))
                {
                    BringWindowToFront(listWindow);
                    return;
                }
            }


            AnlagenbildWindow anlbWindow = new AnlagenbildWindow(id, this, _serverConnection);

        }

        public void OpenMiniWindow(string windowId, int elementId)
        {
            string id = "miniwindow_" + windowId + "_" + elementId;
            foreach (var listWindow in _openToolWindows)
            {
                if (listWindow.Id.Equals(AnlagenbildWindow.AnlagenbildIdName + id))
                {
                    BringWindowToFront(listWindow);
                    return;
                }
            }


            AnlagenbildWindow anlbWindow = new AnlagenbildWindow(id, windowId, elementId, this, _serverConnection);


            var pos = GetMiddle();
            pos.X -= (anlbWindow.DraggableWindow.WindowSize.Width / 2);
            pos.Y -= (anlbWindow.DraggableWindow.WindowSize.Height / 2);
            anlbWindow.DraggableWindow.Position = pos;



        }

        public void OpenFaultHistory()
        {

            FaultHistoryWindow wnd = new FaultHistoryWindow(this, _serverConnection);
        }


        private Classes.Types.Point GetWindowPosition()
        {
            if (_systemTreeWindow == null) { return new Classes.Types.Point(0, 0); }


            //next to systemtree
            int left = this._systemTreeWindow.DraggableWindow.Position.X + this._systemTreeWindow.DraggableWindow.WindowSize.Width + 5;
            int nbrOfWindows = _openToolWindows.Count - 5; //login, fault, systemtree, start, itself
            int top = 0;
            if (nbrOfWindows > 0) top = nbrOfWindows * 20;
            
            return new Classes.Types.Point()
            {
                X = left,
                Y = top
            };
        }

        private Classes.Types.Point GetMiddle()
        {

            return new Classes.Types.Point()
            {
                X = this._contendArea.ClientWidth / 2,
                Y = this._contendArea.ClientHeight / 2
            };
        }

    }

}